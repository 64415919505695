import { useState } from "react";

export function Table(props) {
    const {data, headers, actions, className} = props;
    const [pageNumber, setPageNumber] = useState(1);
    const [idCallback, setIdCallback] = useState(null);
    const [menuElement, setMenuElemnet] = useState(null);
    const [pageSize, setPageSize] = useState(10);

    const currentClassName = "bg-white border-b dark:bg-gray-900 dark:border-gray-700";
    const otherClassName = "bg-gray-100 border-b dark:bg-gray-800 dark:border-gray-700";

    function changePageSize(e){
        setPageSize(e.target.value);
    }

    function getDataPagination(){
        const dataSliced = data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        if(dataSliced.length < pageSize && data.length > pageSize){
            const nb = (pageSize - dataSliced.length);
            for(let i = 1; i <= nb; i++){
                dataSliced.push({id: -i, empty: true})
            }
        }
        return dataSliced;
    }

    function decreasePageNumber(e){
        e.preventDefault();
        if(pageNumber > 1)
            setPageNumber(pageNumber - 1);
    }

    function increasePageNumber(e){
        e.preventDefault();
        if(pageNumber < (data.length / pageSize))
            setPageNumber(pageNumber + 1);
    }

    let row = 0;
    
    document.onclick = hideMenu; 

    function hideMenu() { 
        const contextMenu = document.getElementById("contextMenu");
        if(contextMenu != null) contextMenu.style.display = "none" 
    } 

    function doCallback(e, callback){
        e.preventDefault();
        callback(idCallback);
    }

    function rightClick(e, element) { 
        e.preventDefault(); 
        setIdCallback(element.id);
        setMenuElemnet(element);

        if (document.getElementById("contextMenu").style.display == "block"){ 
            hideMenu(); 
        }else{ 
            var menu = document.getElementById("contextMenu")      
            menu.style.display = 'block'; 
            menu.style.left = e.pageX + "px"; 
            menu.style.top = e.pageY + "px"; 
        } 
    } 

    function doUpdateAction(e, id){
        if(actions == null) return;
        let updateAction = null;
        for(const action of actions){
            if(action.key === "update") updateAction = action;
        }
        if (updateAction==null) return;
        updateAction.callback(id)
    }

    return (
        <>
            <div className={(className?className: "") + " overflow-x-auto shadow-md rounded dark:shadow-lg"}>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                        <tr key="0">
                            {
                                headers.map((header) => {                         
                                    return (
                                        <th scope="col" key={header.key} className="py-3 px-6 whitespace-nowrap max-w-[300px]">
                                            {header.text}
                                        </th>
                                    )
                                })
                            }
                            <th key="actions" className="py-3 px-6 text-right"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length == 0 ? 
                            <tr className={currentClassName}>                                
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white" colSpan="100%">
                                    Aucune entrée
                                </th>
                            </tr>
                            :
                            getDataPagination().map((element) => {
                                row++;
                                return (
                                    element.empty?<tr className="bg-white dark:bg-gray-900 dark:border-gray-700 h-[53px]"><td></td></tr>:
                                    <tr key={element.id} className={row%2 == 1 ? currentClassName : otherClassName} onContextMenu={(e) => {rightClick(e, element)}} onDoubleClick={(e) => {doUpdateAction(e, element.id)}}>
                                        {
                                            props.headers.map((header) => {
                                                return (
                                                    <td key={element.id + "-" + header.key} scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        {element[header.key]}
                                                    </td>
                                                )
                                            })
                                        }
                                        <td className={"py-4 px-6 text-right whitespace-nowrap"}>
                                            {                                            
                                                actions==undefined?<></>:
                                                actions.map((action) => {
                                                    if(!action.onlyRightClick){
                                                        if(action.condition == undefined || action.condition(element)){
                                                            return(
                                                                <button key={action.text} onClick={(e) => {e.preventDefault(); action.callback(element.id)}} className={"font-medium mr-5 text-"+action.color+"-600 dark:text-"+action.color+"-500 hover:underline"}>{action.text}</button>
                                                            )
                                                        }
                                                    }
                                                    return <></>
                                                    
                                                })
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {data.length == 0 ? <></> : 
                <nav className="flex justify-between items-center pt-4 pb-2" aria-label="Table navigation">
                    <span className="text-sm font-normal text-gray-500 dark:text-gray-400"> <span className="font-semibold text-gray-900 dark:text-white">{(pageNumber-1) * pageSize + 1}-{(pageNumber*pageSize)>data.length?data.length:pageNumber*pageSize}</span> / <span className="font-semibold text-gray-900 dark:text-white">{data.length}</span></span>
                    <ul className="inline-flex items-center -space-x-px">
                        <li className="mr-5">                            
                            <select
                                id="element"
                                name="element"
                                className="block w-full h-10 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-blue-600 sm:text-sm sm:leading-6 dark:bg-gray-800 dark:text-gray-100 dark:ring-gray-700"
                                value={pageSize}
                                onChange={changePageSize}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="30">30</option>
                            </select>
                        </li>
                        <li onClick={decreasePageNumber}>
                            <button className="block h-10 py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span className="sr-only">Previous</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            </button>
                        </li>
                        <li onClick={increasePageNumber}>
                            <button className="block h-10 py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                <span className="sr-only">Next</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                            </button>
                        </li>
                    </ul>
                </nav>
            }
            <div id="contextMenu" className="context-menu" style={{display: "none"}}> 
                <ul className="menu"> 
                    {   
                        menuElement == null || actions == null?<></>:
                        actions.map((action) => {
                            if(action.condition == null || action.condition(menuElement)){
                                return (<li key={action.key} className={action.key === "delete"?"trash":""}><button onClick={(e) => {doCallback(e, action.callback)}}><i className="fa fa-share" aria-hidden="true"></i>{action.text}</button></li>)
                            }
                            return <></>
                        })
                    }
                </ul> 
            </div> 
        </>
    )

}