import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmModal } from "../../../component/ConfirmModal";
import InputText from "../../../component/input/InputText";
import { deleteDate, getDate, saveDate } from "../../../api/agenda";
import Breadcrumb from "../../../component/Breadcrumb";
import InputSelect from "../../../component/input/InputSelect";
import { allTemplates } from "../../../templates/Template";

const defaultForm = {
    "id": 0,
    "title": "",
    "date": "",
    "description": "",
    template: null
}

const defaultErrorForm = {
    title: {},
    date: {},
    description: {},
}

export function DateForm(props){
    const {id} = useParams();
    const [formValues, setFormValues] = useState(defaultForm)
    const [templateValues, setTemplateValues] = useState([])
    const [errorForm, setErrorForm] = useState(defaultErrorForm);
    const [deleteModal, setDeleteModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(id !== "0") {
            getDate(id).then((data) => {
                setFormValues({
                    ...data.data,
                    state: data.data.active ? 1 : 2,
                    template: data.data.template ? data.data.template : null
                });
                setTemplateValues(data.data.forms)
            });
        }
    }, [id]);

    function hanldeKeyDown(e){
        if(e.keyCode === 27){
            setDeleteModal(false);
        }
    }

    function showDeleteModal(e){
        e.preventDefault();
        setDeleteModal(true);
    }

    function performDelete(){
        deleteDate(id).then(() => {
            navigate("../agenda");
        })
    }

    function handleChange(e) {
        const {id, value} = e.target;
        setFormValues({
            ...formValues,
            [id]: value
        })
    }

    function checkForm(){
        
        let tempErrorForm = defaultErrorForm;
        let error = false;
        const requiredFields = ["title", "date"];
        for(const requiredField of requiredFields){
            if(formValues[requiredField] == ""){
                tempErrorForm = {
                    ...tempErrorForm,
                    [requiredField] : {
                        error: true,
                        message: "Champ obligatoire"
                    }
                }
                error = true;
            }
        }
        setErrorForm(tempErrorForm);
        return !error;
    }

    function save(e){
        e.preventDefault();
        if(checkForm()){
            saveDate(id, {...formValues, forms: templateValues}).then(() => {
                navigate("../agenda");
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    const templates = allTemplates();

    function getTemplateById(id){
        for (const i in templates){
            const template = templates[i];
            if (template.id == id) {
                return template
            }
        }
        return null
    }

    function handleChangeTemplate(e){
        handleChange(e);
        const template = getTemplateById(e.target.value);
        const formTemplate = {}
        for (const input of template.forms){
            formTemplate[input.key] = input.default ? input.default : ""
        }
        setTemplateValues(formTemplate)
    }

    function handleChangeTemplateForms(e){
        const {id, value} = e.target;
        setTemplateValues({
            ...templateValues,
            [id]: value
        })
    }

    return (
        <div className="" onKeyDown={hanldeKeyDown}>
            <Breadcrumb className="sm:pl-5" history={[{url: "../agenda", key: "Agenda"}]} current={formValues.id == 0 ? "Nouvelle date": formValues.title}/>
            <form className="p-1 sm:p-10 pt-5">             
                <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                    <h2 className="text-2xl font-bold dark:text-white">{id==="0"?"Nouvelle date":"Modification d'une date"}</h2>
                    {
                        id==="0"?<></>:
                        <div className="mb-6 col-start-1">
                            <label htmlFor="id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Id</label>
                            <input type="number" id="id" onChange={handleChange} className="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={formValues.id} required disabled/>
                        </div>
                    }
                    <div className="mb-6 col-start-1">
                        <InputText onChange={handleChange} id="title" label="Titre" value={formValues.title} error={errorForm.title} required/>
                    </div>
                    <div className="mb-6">
                        <InputText onChange={handleChange} id="date" label="Date" value={formValues.date} error={errorForm.date} required/>
                    </div>
                    <div className="mb-6 md:col-span-2">
                        <InputText onChange={handleChange} id="description" label="Description" value={formValues.description} error={errorForm.description}/>
                    </div>
                    <div className="mb-6">
                        <InputSelect options={[{id: null, key: "Aucune information"}, ...templates]} onChange={handleChangeTemplate} id="template" label="Template" value={formValues.template} error={errorForm.template}/>
                    </div>
                </div>

                {
                    formValues.template == null ? <></> :
                    <>
                        <h2 className="w-full text-center border-b border-gray-400 leading-[0.1em] mb-6"><span className="text-gray-600 text-center dark:text-gray-400 p-10 bg-white dark:bg-[#111827]">Informations</span></h2>
                        <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                            {
                                getTemplateById(formValues.template)?.forms?.map((input) => {
                                    return <InputText key={input.key} onChange={handleChangeTemplateForms} id={input.key} label={input.label} value={templateValues[input.key]} error={errorForm.description}/>                                    
                                })
                            }
                        </div>
                    </>
                }

                <div className="grid md:grid-cols-4 md:gap-6">
                    <button onClick={save} className="mb-6 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enregistrer</button>
                    {id==="0"?<></>:<button onClick={showDeleteModal} className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Supprimer</button>}
                </div>

            </form>
            <ConfirmModal isShowed={deleteModal} hide={() => {setDeleteModal(false)}} valid={performDelete} />
        </div>
    )
}