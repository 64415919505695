import { useEffect, useState } from "react";
import { Table } from "../../../component/Table";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../../component/ConfirmModal";
import { deleteMember, getAllMembers } from "../../../api/members";
import Success from "../../../component/alert/Success";
import Breadcrumb from "../../../component/Breadcrumb";
import { roles } from "./Form";
import { getByKey } from "../../../utils/getObjById";
import { FaPlus } from "react-icons/fa";

export function Members() {
    function loadMembers() {
        getAllMembers().then((data) => {
            setMembers(data.data);
        })
    }

    useEffect (() => {
        loadMembers();
    }, []) 
    const [members, setMembers] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [memberId, setMemberId] = useState(-1);
    const [success, setSuccess] = useState({show: false, message: ""});
    const navigate = useNavigate();
    const struct = [
        {key: "name", text: "Nom"},
        {key: "year", text: "Année"},
        {key: "roles", text: ""}
    ]

    function getRoleComponent(member){     
        const memberRoles = [];
        if (member.musicien != null){
            memberRoles.push("musicien");
        }
        if (member.comite != null){
            memberRoles.push("comite");
        }
        if (member.direction != null){
            memberRoles.push("direction");
        }
        if (member.filleHonneur != null){
            memberRoles.push("filleHonneur");
        }
        if (member.porteDrapeau != null){
            memberRoles.push("porteDrapeau");
        }
        if (member.school != null){
            memberRoles.push("school")
        }

        return(
            <>
            {
                memberRoles.length < 1?<></>:
                <>
                    {
                        memberRoles.map((role) => {
                            return <span key={role} className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                                {
                                    getByKey(roles, role).label
                                }
                            </span>
                        })                    
                    }
                </>
            }
            </>
        )
    }

    function getData(){
        return members.map((member) => {
            return {
                ...member,
                roles: getRoleComponent(member)
            }
        })
    }

    function confirmDelete(id) {
        setMemberId(id);
        setShowConfirmModal(true);
    }

    function performDelete(){
        if(memberId > 0){
            deleteMember(memberId).then(() => {
                loadMembers();
                setSuccess({show: true, message: "Suppression effectuée!"});
            })
        }
        setShowConfirmModal(false);
    }

    const actions = [
        {callback: (id) => {navigate("./"+id)}, text: "Modifier", color: "blue", key: "update"},
        {callback: confirmDelete, text: "Supprimer", color: "red", key: "delete"}
    ]

    return (    
        <div>
            <Breadcrumb className="sm:pl-5" current="Membres actifs"/>
            <div className="flex items-end justify-between px-1 sm:px-10 py-5">
                <h2 className="text-2xl sm:text-4xl w-full sm:w-auto text-center font-bold dark:text-white">Membres actifs</h2> 
                <button type="button" onClick={() => {navigate("./0")}} className="hidden sm:flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Créer</button>   
            </div>

            <div className="m-1 sm:m-10 mb-20 sm:mb-10">
                <Table headers={struct} data={getData(members)} actions={actions}/>
            </div>

            <div onClick={() => {navigate("./0")}} className="sm:hidden h-[60px] w-[60px] bg-blue-500 dark:bg-blue-700 rounded-lg fixed lg:hidden bottom-4 right-4 flex">
                <FaPlus className="text-white h-[40px] w-[40px] m-auto"/>
            </div>
            <ConfirmModal isShowed={showConfirmModal} hide={() => {setShowConfirmModal(false)}} valid={performDelete}/>
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}