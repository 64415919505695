import { PhotoIcon } from "@heroicons/react/20/solid"
import { useEffect, useState } from "react";


export default function InputImage(props){
    const {label, onChange, value, id} = props;

    const [dragging, setDragging] = useState(false);
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if (value != null && value != undefined && value != ""){
            setSrc(value);
        }
    }, [value])

    function handleDragHover(e){
        e.preventDefault();
        setDragging(true);
    }

    function handleDragLeave(e){
        e.preventDefault();
        setDragging(false);
    }


    function changeFile(file){
        const blob = new Blob([file], { type: file.type })        
        var reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = function() {
            var base64data = reader.result;   
            setSrc(base64data)
            onChange({target: {id: id, value: base64data}})
        }
    }

    function handleDrop(e){
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0];
        changeFile(file);
    }

    function handleChange(e){
        const file = e.target.files[0];
        changeFile(file);
    }

    function removeImage(){
        setSrc(null);
        onChange({target: {id: id, value: null}});
    }

    return <div>
        {
            label == undefined ? <></> :
            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                {label}
            </label>
        }
        <div onDrop={handleDrop} onDragLeave={handleDragLeave} onDragOver={handleDragHover} className={"mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 dark:border-gray-100/25 " + (dragging? "px-5 py-9" : "px-6 py-10")}>
            {
                src == null ? 
                <div className="text-center">
                    <PhotoIcon className={"mx-auto text-gray-300 " + (dragging ? "h-14 w-14" : "h-12 w-12" )} aria-hidden="true" />
                    <div className={"mt-4 flex leading-6 text-gray-600 dark:text-gray-300 " + (dragging? "text-base" : "text-sm")} >
                        <label
                            htmlFor={"file-upload-" + id}
                            className="relative cursor-pointer rounded-md font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
                        >
                        <span>Upload a file</span>
                        <input onChange={handleChange} id={"file-upload-" + id} name={"file-upload-" + id} type="file" className="sr-only" />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className={"leading-5 text-gray-600 dark:text-gray-300 " + (dragging? "text-sm" : "text-xs")}>PNG, JPG, GIF</p>
                </div>
                :
                <div className="flex flex-col">
                    <img alt="" src={src} className="max-h-60"/>
                    <button onClick={removeImage} className="mt-5 text-blue-700 dark:text-blue-500 underline">Supprimer l'image</button>
                </div>
            }
        </div>
    </div>
}