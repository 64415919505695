const instruments = [
    {id: 1, name: "Cornet mib"},
    {id: 2, name: "Cornet solo"},
    {id: 3, name: "Cornet répiano"},
    {id: 4, name: "Cornet 2-3"},
    {id: 5, name: "Bugle"},
    {id: 6, name: "Alto solo"},
    {id: 7, name: "Alto 1-2"},
    {id: 8, name: "Baryton"},
    {id: 9, name: "Trombone"},
    {id: 10, name: "Trombone basse"},
    {id: 11, name: "Euphonium"},
    {id: 12, name: "Basse mib"},
    {id: 13, name: "Basse sib"},
    {id: 14, name: "Percussion"},
]

const comiteRoles = [
    {id: 1, name: "Président"},
    {id: 2, name: "Vice-président"},
    {id: 3, name: "Secrétaire"},
    {id: 4, name: "Caissier"},
    {id: 5, name: "Membre"},
]

function lastId(arr){
    let id = -1;
    for (const element of arr) {
        if(element.id > id){
            id = element.id;
        }
    }
    return id;    
}

function getIdByRole(strRole) {
    for (const role of comiteRoles) {
        if (role.name == strRole) {
            return role.id;
        }
    }
    return lastId(comiteRoles) + 1;
}

function getIdByInstrument(strInstrument) {
    for (const instrument of instruments) {
        if (instrument.name == strInstrument) {
            return instrument.id;
        }
    }
    return lastId(instruments) + 1;
}

export {instruments, comiteRoles, getIdByRole, getIdByInstrument};