export default function InputChecked(props) {
    const {label, value, error, onChange, id} = props;
    
    return (   
        <>
            <input checked={value} onChange={() => {onChange({target: {id: id, value:!value}})}} id={id} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label onClick={() => {onChange({target: {id: id, value:!value}})}} className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</label>
            <p className="mt-[-30px] mb-[-20px] text-sm text-red-600 dark:text-red-500">{error?.message}</p>
        </>
    )
}