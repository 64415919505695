import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import ActivMember from "./pages/ActivMember";
import Calendar from "./pages/Calendar";
import "./index.css";
import "../node_modules/flowbite/dist/flowbite"
import Album from "./pages/Album";
import Contact from "./pages/Contact";
import HonorMember from "./pages/HonorMember";
import History from "./pages/History";
import AdminLayout from "./pages/admin/AdminLayout";
import { Agenda } from "./pages/admin/agenda/Agenda";
import { DateForm } from "./pages/admin/agenda/Form";
import { UserForm } from "./pages/admin/users/Form";
import { MemberForm } from "./pages/admin/members/Form";
import { Users } from "./pages/admin/users/Users";
import { AdminHome } from "./pages/admin/AdminHome";
import { Roles } from "./pages/admin/roles/Roles"
import { auth } from "./utils/auth";

import Login from "./pages/Login";
import RedirectLogin from "./component/auth/RedirectLogin";
import { Members } from "./pages/admin/members/Members";
import { MembreHonneurs } from "./pages/admin/membreHonneurs/membreHonneurs";
import { MembreHonneurForm } from "./pages/admin/membreHonneurs/Form";
import { ContactForm } from "./pages/admin/contact/Form";
import NotFound from "./pages/404";
import EMA from "./pages/EMA";
import Template from "./templates/Template";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/" element={<Layout />}>
          <Route path="ema" element={<EMA />} />
          <Route path="history" element={<History />} />
          <Route path="membres_actifs" element={<ActivMember />} />
          <Route path="membres_honneur" element={<HonorMember />} />
          <Route path="album" element={<Album />} />
          <Route path="agenda" element={<Calendar />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="events/:id" element={<Template />} />
        </Route>

        <Route path="/admin" element={<AdminLayout />}>     
          {
            auth(false)?<>
              <Route index element={<AdminHome />} />
              <Route path="agenda" element={<Agenda />} />
              <Route path="agenda/:id" element={<DateForm />} />
              <Route path="users" element={<Users />}/>
              <Route path="users/:id" element={<UserForm />} />
              <Route path="members" element={<Members />}/>
              <Route path="members/:id" element={<MemberForm />} />
              <Route path="membreHonneurs" element={<MembreHonneurs />}/>
              <Route path="membreHonneurs/:id" element={<MembreHonneurForm />} />
              <Route path="contact" element={<ContactForm />} />
              <Route path="roles" element={<Roles />} />
            </> : <>
              <Route index element={<RedirectLogin />} />
              <Route path="*" element={<RedirectLogin />} />
            </>
          }
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);