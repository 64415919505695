export const MONTH = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

export function mysqlDateToString(date){
    const d = new Date(date);
    let day = d.getDate();
    if(day < 10) day = '0' + day;
    let month = MONTH[d.getMonth()];
    let year = d.getFullYear();
    return `${day} ${month} ${year}`;
}

export function jsDateToMysqlString(date){
    let day = date.getDate();
    if(day < 10) day = '0' + day;
    let month = date.getMonth() + 1;
    if(month < 10) month = '0' + month;
    let year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

/**
 * 
 * @param String sDuration 
 * Transforme un string 5y, 3m, 10d en un objet
 */
export function getDuration(sDuration){
    let i = 0;
    let duration = "";
    while(!isNaN(sDuration[i]) && i < (sDuration.length - 1)){
        duration += sDuration[i];
        i++;
    }

    const error = sDuration.length > i+1 || (sDuration[i] != "y" && sDuration[i] != "m" && sDuration[i] != "d")

    const obj = {
        error: error,
        duration: duration,
        unit: sDuration[i]
    };
    return obj;
}