import { TagIcon, MusicalNoteIcon, CakeIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { getAllMembers } from "../api/members";
import { comiteRoles, getIdByInstrument, getIdByRole, instruments } from "../utils/enum";

const ActivMember = () => {

    const [members, setMembers] = useState({
        comites: [],
        directions: [],
        members: [],
        filleHonneurs: [],
        porteDrapeaux: []
    });


    function loadMembers() {
        getAllMembers().then((data) => {
            const tempMembers = {
                comites: [],
                directions: [],
                members: [],
                filleHonneurs: [],
                porteDrapeaux: []
            }
            for(const member of data.data){
                if(member.comite != null){
                    tempMembers.comites.push({...member, ...member.comite});
                }
                if(member.direction != null){
                    tempMembers.directions.push({...member, ...member.direction});
                }
                if(member.musicien != null){
                    tempMembers.members.push({...member, ...member.musicien});
                }
                if(member.filleHonneur != null){
                    tempMembers.filleHonneurs.push({...member, ...member.filleHonneur});
                }
                if(member.porteDrapeau != null){
                    tempMembers.porteDrapeaux.push({...member, ...member.porteDrapeau});
                }                
            }

            //Sort comites and musicians
            tempMembers.comites.sort((a, b) => getIdByRole(a.role) - getIdByRole(b.role));
            tempMembers.members.sort((a, b) => getIdByInstrument(a.instrument) - getIdByInstrument(b.instrument));
            setMembers(tempMembers);
        })
    }

    function getNbActivite(member) {
        let nbActivite = 0;
        const year = new Date().getFullYear();
        nbActivite = year - member.year
        return nbActivite;
    }

    function hasJubilaire(member) {
        const nbActivite = getNbActivite(member);
        return (nbActivite > 0 && nbActivite % 5 === 0)
    }

    useEffect (() => {
        loadMembers();
    }, []) 

    return <div className="p-2 max-w-[1400px] m-auto">    
        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Membres</h1>
            <small className="font-semibold text-gray-500 text-lg md:ml-5 md:text-4xl">actifs</small>
        </div>      
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 grid-rows-1 mt-10">
            <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
                <time className="md:text-lg text-sm font-semibold text-gray-900">Membres du comité</time>
                <div className="mt-3 grid grid-cols-1 lg:grid-cols-2">
                        {members.comites.map((comite) => {
                            return (     
                                <div key={comite.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                    <img className="h-16 mb-3 mr-3 rounded-lf sm:mb-0" src={comite.photo ? comite.photo : "/img/pp/anonym.jpg"} alt=""/>
                                    <div className="text-gray-600">
                                        <div className="font-medium text-gray-900 md:text-normal text-sm">
                                            {comite.name}
                                        </div>    
                                        <div>                        
                                            <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                                <TagIcon className="h-4 w-6 text-gray-500" />
                                                {comite.role}
                                            </span> 
                                            {
                                                !hasJubilaire(comite) ? <></> :              
                                                <span className="ml-2 inline-flex items-center text-xs font-normal text-gray-500">
                                                    <CakeIcon className="h-4 w-6 text-gray-500" />
                                                    {getNbActivite(comite) + " années"}
                                                </span>   
                                            }        
                                        </div>
                                    </div>
                                </div>
                            ) 
                        })}
                </div>
            </div>
            <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
                <time className= "md:text-lg text-sm font-semibold text-gray-900">Direction</time>            
                    <div className="mt-3 grid grid-cols-1 lg:grid-cols-2">
                        {members.directions.map((direction) => {
                            return (     
                                <div key={direction.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                    <img className="h-16 mb-3 mr-3 rounded-lg sm:mb-0" src={direction.photo ? direction.photo : "/img/pp/anonym.jpg"} alt=""/>
                                    <div className="text-gray-600">
                                        <div className="font-medium text-gray-900 md:text-normal text-sm">{direction.name}</div>
                                        <div>
                                        {
                                            !hasJubilaire(direction) ? <></> :              
                                            <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                                <CakeIcon className="h-4 w-6 text-gray-500" />
                                                {getNbActivite(direction) + " années"}
                                            </span>   
                                        }   
                                        </div>
                                    </div>
                                </div>    
                            ) 
                        })}
                    </div>
            </div>
        </div>

        <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
            <time className= "md:text-lg text-sm font-semibold text-gray-900">Musiciens</time>
            <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    {members.members.map((member) => {
                        return (     
                            <div key={member.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                <img className="h-16 mb-3 mr-3 rounded-lg sm:mb-0" src={member.photo ? member.photo : "/img/pp/anonym.jpg"} alt=""/>
                                <div className="text-gray-600">
                                    <div className="font-medium text-gray-900 md:text-normal text-sm">{member.name}</div>                            
                                    <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                        <MusicalNoteIcon className="h-4 w-6 text-gray-500" />
                                        {member.instrument}
                                    </span> 
                                    {
                                        !hasJubilaire(member) ? <></> :              
                                        <span className="ml-2 inline-flex items-center text-xs font-normal text-gray-500">
                                            <CakeIcon className="h-4 w-6 text-gray-500" />
                                            {getNbActivite(member) + " années"}
                                        </span>   
                                    }   
                                </div>
                            </div>
                        ) 
                    })}
            </div>
        </div>
        
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 grid-rows-1">
            <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
                <time className= "md:text-lg text-sm font-semibold text-gray-900">Filles d'honneur</time>
                <div className="mt-3 grid grid-cols-1 lg:grid-cols-2">
                        {members.filleHonneurs.map((f) => {
                            return (     
                                <div key={f.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                    <img className="h-16 mb-3 mr-3 rounded-lg sm:mb-0" src={f.photo ? f.photo : "/img/pp/anonym.jpg"} alt=""/>
                                    <div className="text-gray-600">
                                        <div className="font-medium text-gray-900 md:text-normal text-sm">{f.name}</div>
                                        
                                        {
                                            !hasJubilaire(f) ? <></> :              
                                            <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                                <CakeIcon className="h-4 w-6 text-gray-500" />
                                                {getNbActivite(f) + " années"}
                                            </span>   
                                        }   
                                    </div>
                                </div>
                            ) 
                        })}
                </div>
            </div>
            <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
                <time className= "md:text-lg text-sm font-semibold text-gray-900">Portes-drapeaux</time>            
                    <div className="mt-3 grid grid-cols-1 lg:grid-cols-2">
                        {members.porteDrapeaux.map((p) => {
                            return (     
                                <div key={p.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                    <img className="h-16 mb-3 mr-3 rounded-lg sm:mb-0" src={p.photo ? p.photo : "/img/pp/anonym.jpg"} alt=""/>
                                    <div className="text-gray-600">
                                        <div className="font-medium text-gray-900 md:text-normal text-sm">{p.name}</div>
                                        
                                        {
                                            !hasJubilaire(p) ? <></> :              
                                            <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                                <CakeIcon className="h-4 w-6 text-gray-500" />
                                                {getNbActivite(p) + " années"}
                                            </span>   
                                        }   
                                    </div>
                                </div>
                            ) 
                        })}
                    </div>
            </div>
        </div>

    </div>
};
  
export default ActivMember;