import { Modal, Button } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";

export function ConfirmModal(props){
    const {isShowed, hide, valid, message, message2, title} = props;

    function handleKeyDown(e){
        if(isShowed) {
            if(e.key == "Escape"){
                hide();
            }
            if(e.key == "Enter"){
                e.preventDefault();
                valid();
            }
        }
    }
            
    document.addEventListener("keydown", handleKeyDown);

    

    return (        
        <Modal
            show={isShowed}
            size="md"
            popup={true}
            onClose={hide}
        >
            <Modal.Header>
                <span className="ml-3">{title?title:"Suppression"}</span>
            </Modal.Header>

            <Modal.Body>
            <div className="text-center">
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200" />
                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                    <p>{message?message:"Êtes-vous sure de vouloir supprimer cet élément?"}</p>
                    <p className="text-sm">{message2?message2:""}</p>
                </h3>
                <div className="flex justify-center gap-4">
                <Button
                    color="failure"
                    onClick={valid}
                >
                    Oui, je suis sure
                </Button>
                <Button
                    color="gray"
                    onClick={hide}
                >
                    Non, annuler
                </Button>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}