export default function InputRadio(props) {
    const {id, options, value, onChange} = props;

    return (        
        <fieldset key={id}>
            <div className="space-y-5">
                {options.map((option) => (
                    <div key={option.id} className="relative flex items-start">
                        <div className="flex h-6 items-center cursor-pointer">
                            <input
                                id={option.id}
                                name={id}
                                type="radio"
                                checked={value == option.id ? "checked" : ""}
                                className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-offset-0 cursor-pointer"
                                onChange={() => {onChange({target: {id: id, value: option.id}})}}
                            />
                        </div>
                        <div className="ml-3 text-sm leading-6 cursor-pointer">
                            <label htmlFor={option.id} className="font-medium text-gray-900 cursor-pointer dark:text-gray-100">
                                {option.key}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </fieldset>
    )
}