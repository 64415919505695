import { useEffect, useState } from "react";
import { Table } from "../../../component/Table";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../../component/ConfirmModal";
import { deleteMembreHonneur, getAllMembreHonneurs } from "../../../api/membreHonneurs";
import Success from "../../../component/alert/Success";
import Breadcrumb from "../../../component/Breadcrumb";
import { FaCross, FaPlus } from "react-icons/fa";

export function MembreHonneurs() {
    function loadMembreHonneurs() {
        getAllMembreHonneurs().then((data) => {
            setMembreHonneurs(data.data);
        })
    }

    useEffect (() => {
        loadMembreHonneurs();
    }, []) 
    const [membreHonneurs, setMembreHonneurs] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [membreHonneurId, setMembreHonneurId] = useState(-1);
    const [success, setSuccess] = useState({show: false, message: ""});
    const navigate = useNavigate();
    const struct = [
        {key: "name", text: "Nom"},
        {key: "role", text: "Role"},
        {key: "date", text: "Année"},
        {key: "village", text: "Village"},
    ]

    function getNameComponent(name, alive){
        return(
            <>
            {
                alive?<>{name}</>:<div className="flex">
                    {name} <FaCross className="ml-5"></FaCross>
                </div>
            }
            </>
        )
    }

    function getRoleComponent(role){
        let roleText = "Membre d'honneur"
        if(role==1) roleText = "Président d'honneur";
        if(role==2) roleText = "Directeur d'honneur";
        return(
            <>
            {
                <span className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                    {roleText}
                </span>
            }
            </>
        )
    }

    function getData(){
        return membreHonneurs.map((membreHonneur) => {
            return {
                ...membreHonneur,
                name: getNameComponent(membreHonneur.name, membreHonneur.alive),
                role: getRoleComponent(membreHonneur.role)
            }
        })
    }

    function confirmDelete(id) {
        setMembreHonneurId(id);
        setShowConfirmModal(true);
    }

    function performDelete(){
        if(membreHonneurId > 0){
            deleteMembreHonneur(membreHonneurId).then(() => {
                loadMembreHonneurs();
                setSuccess({show: true, message: "Suppression effectuée!"});
            })
        }
        setShowConfirmModal(false);
    }

    const actions = [
        {callback: (id) => {navigate("./"+id)}, text: "Modifier", color: "blue", key: "update"},
        {callback: confirmDelete, text: "Supprimer", color: "red", key: "delete"}
    ]

    return (    
        <div>
            <Breadcrumb className="pl-5" current="Membres d'honneur"/>
            <div className="flex items-end justify-between px-1 sm:px-10 py-5">
                <h2 className="text-2xl sm:text-4xl w-full sm:w-auto text-center font-bold dark:text-white">Membres d'honneur</h2> 
                <button type="button" onClick={() => {navigate("./0")}} className="hidden sm:flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Créer</button>   
            </div>

            <div className="m-1 sm:m-10 mb-20 sm:mb-1">
                <Table headers={struct} data={getData(membreHonneurs)} actions={actions}/>
            </div>

            <div onClick={() => {navigate("./0")}} className="sm:hidden h-[60px] w-[60px] bg-blue-500 dark:bg-blue-700 rounded-lg fixed lg:hidden bottom-4 right-4 flex">
                <FaPlus className="text-white h-[40px] w-[40px] m-auto"/>
            </div>
            <ConfirmModal isShowed={showConfirmModal} hide={() => {setShowConfirmModal(false)}} valid={performDelete}/>
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}