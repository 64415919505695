import { FaCalendar, FaLocationDot } from "react-icons/fa6"
import React from "react"



export default class Loto {

    static forms(){
        return [
            {key: "date", label: "Date", required: true},
            {key: "subtitle", label: "Sous-titre", required: false},
            {key: "startHour", label: "Heure de début", required: true, default: "19h00"},
            {key: "doorHour", label: "Heure d'ouverture des portes", required: true, default: "17h00"},
        ]        
    }
    
    static render(forms){
        
        return <div className="max-w-[1400px] m-auto p-5">        
            <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
                <h1>Loto</h1>
                <small className="font-semibold text-gray-500 text-lg md:ml-5    md:text-4xl">{forms.subtitle}</small>
            </div>

            <div className="mt-5 flex items-center justify-center text-xl md:text-2xl">
                <FaLocationDot className="mr-2"/>
                <h1>Salle Polyvalente La Florescat Saxon</h1>
            </div>


            <div className="mt-5 flex items-center justify-center text-xl md:text-2xl">
                <FaCalendar className="mr-2"/>
                <h1>{forms.date}</h1>
            </div>

            <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 mb-10 mt-16 text-lg">
                <div href="#" className="flex items-center bg-white border border-gray-200 rounded-lg shadow flex-col p-2">
                    <h1 className="w-full text-center text-2xl mb-3 font-bold">Heures d'ouverture</h1>
                    <div className="flex flex-row items-center">
                        <h1>Ouverture des portes à <span className="font-bold text-green-700">{forms.doorHour}</span> h</h1>
                    </div>
                    <div className="flex flex-row items-center">
                        <h1>Début du loto à <span className="font-bold text-green-700">{forms.startHour}</span> h</h1>
                    </div>
                </div>

                <div href="#" className="flex items-center bg-white border border-gray-200 rounded-lg shadow flex-col p-2 text-2xl">
                    <h1 className="w-full text-center text-2xl mb-3 font-bold">Lots à gagner</h1>
                    <div className="flex flex-row items-center">
                        <h1 className="ml-3"><span className="font-bold text-orange-700">33</span> Séries</h1>
                    </div>
                    <div className="flex flex-row items-center">
                        <h1 className="ml-3">3x <span className="font-bold text-orange-700">1'000.-</span></h1>
                    </div>
                </div>

                <div href="#" className="flex items-center bg-white border border-gray-200 rounded-lg shadow flex-col p-2 text-base">
                    <h1 className="w-full text-center text-2xl mb-3 font-bold">LotoCool</h1>
                    <div className="flex flex-row items-center">
                        <h1>Gagner sans se déplacer</h1>
                    </div>
                    <div className="flex flex-row items-center">
                        <h1 className="">jusqu'à <span className="text-blue-700">1'000.-</span></h1>
                    </div>
                    <div className="flex flex-row items-center">
                        <h1>Vous pouvez commander des abonnements</h1>
                    </div>
                    <div className="flex flex-row items-center">
                        <h1>Denis Falquet <span className="ml-1 text-gray-700">079 440 17 60</span></h1>
                    </div>
                </div>

            </div>

        </div>
    }
}