import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const RedirectLogin = () => {
    let navigate = useNavigate();
    useEffect(() => {
        navigate("/login");
    });
    return <h1> </h1>
};
  
export default RedirectLogin;