import { useEffect, useState } from 'react';
import { HiArrowSmDown } from 'react-icons/hi';
import { useNavigate, Link } from 'react-router-dom';
import album from "../helpers/album";
import { getAllDates } from '../api/agenda';
import { facebook, instagram } from '../utils/resaux';

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

const Home = () => {
  let navbarVisible = false;

  const [dates, setDates] = useState([]);

  function loadDates() {
      getAllDates().then((data) => {
          setDates(data.data);
      })
  }

  function toogleNavbar(event) {
    if(navbarVisible){
      hideNavbar();
    } else {
      displayNavbar();
    }
    event.stopPropagation();
  }
  
  function displayNavbar(){
    const navbar = document.getElementById("navbar-default");
    navbar.classList.remove("hidden");
    navbarVisible = true;
  }
  
  function hideNavbar(){
    const navbar = document.getElementById("navbar-default");
    navbar.classList.add("hidden");
    navbarVisible = false;
  }
  
  document.onclick = function (e) {
    if(e.target.id != "btn-toogle-navbar") hideNavbar();
  }

  require("./homepage.css")

  const navigate = useNavigate();

  const navigation = {
    main: [
      { name: 'Accueil', href: '' },
      { name: 'Ecole de musique', href: 'ema' },
      { name: 'Histoire', href: 'history' },
      { name: 'Membres actifs', href: 'membres_actifs' },
      { name: 'Membres d\'honneur', href: 'membres_honneur' },
      { name: 'Agenda', href: 'agenda' },
      { name: 'Album', href: 'album' },
      { name: 'Contact', href: 'contact' },
      { name: 'Se connecter', href: 'login' },
    ],
    social: [
      {
        name: 'Facebook',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: '#',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      } 
    ],
  }

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      // If the element is visible
      if (entry.isIntersecting) {
        // Add the animation class
        if(entry.target.id.includes("right")) entry.target.classList.add('slide-right');
        if(entry.target.id.includes("left")) entry.target.classList.add('slide-left');
        if(entry.target.id.includes("fade")) entry.target.classList.add('fade-in-fwd');
      }

    })
  })
  
  const photos = []

  for(const category of album){
      for(const photo of category.photos){
          photos.push({url: category.location+"/"+photo, category: category.category});
      }
  }

  shuffle(photos)

  const tenPhotos = []
  
  for(let i = 0; i < 10; i ++){
    tenPhotos.push(photos[i]);
  }

  useEffect(() => {
    let element = document.querySelector('#slide-right-animation')
    if (element != null) observer.observe(element)
    element = document.querySelector('#slide-left-animation')
    if (element != null) observer.observe(element)
    element = document.querySelector('#slide-right-animation-2')
    if (element != null) observer.observe(element)
    element = document.querySelector('#slide-left-animation-2')
    if (element != null) observer.observe(element)
    element = document.querySelector('#fade-in-animation')
    if (element != null) observer.observe(element)
  })

  useEffect (() => {
    loadDates();
  }, [])

  function navigateTo(href) {
    window.scrollTo(0,0);
    navigate("/" + href);
  }

  function goToContent(){
    const element = document.getElementById("content");
    element.scrollIntoView({behavior: 'smooth'});
  }

  function convertToDate(dateString) {
    //  Convert a "dd.MM.yyyy" string into a Date object
    let d = dateString.split(".");
    let dat = new Date(d[2] + '.' + d[1] + '.' + d[0]);
    return dat;     
}

  function getNextEvent(nbEvents = 3){
    const nextEvents = []

    if(dates.length == 0) return nextEvents;

    // we assume that the array is already sorted otherwise we should sort it here

    // get indice for the closest next event
    let i;    
    for (i = 0; i < dates.length; i++){
      const dateString = dates[i].date.slice(0, 10); //format dd.mm.yyyy
      const date = convertToDate(dateString);
      if(date > new Date()) break;
    }


    const nbNextEvents = dates.length - i;
    if (nbNextEvents < nbEvents) i -= nbEvents - nbNextEvents;
    if (i < 0) i = 0; 

    const n = nbEvents < dates.length ? nbEvents : dates.length;
    for(let j = 0; j < n; j++){
      nextEvents.push(dates[i + j]);
    }

    return nextEvents;
  }

    return <div>
      <div className='h-[100vh]'>        
        <div className="text-white backdrop-brightness-50 h-[100vh] w-full absolute z-40">
          <div className='w-full flex flex-row-reverse'>
            <button onClick={toogleNavbar} id="btn-toggle-navbar" type="button" className="mt-1 inline-flex items-center p-2 ml-3 mr-5 text-sm text-white rounded-lg xl:hidden focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
            </button>
          </div>
          <div className="hidden w-full xl:w-auto fixed" style={{zIndex: "999"}} id="navbar-default">
                  <ul className="mx-auto text-gray-900 bg-white flex flex-col p-4 mt-3 border border-gray-100 rounded-lg xl:flex-row xl:space-x-8 xl:space-y-0 xl:mt-0 xl:text-sm xl:font-medium xl:border-0 text-center text-lg font-medium space-y-4">
                      {
                        navigation.main.map((item) => {
                          return (
                            <li key={item.href}>
                                <button onClick={() => {navigateTo(item.href)}} className="text-sm">{item.name}</button>
                            </li>
                          )
                        })
                      }
                  </ul>
                </div>
          <div className='hidden xl:block float-right space-x-8 divide-transparent mr-[70px] mt-5'>
            {
              navigation.main.map((link) => {
                return (                  
                  <Link key={link.href} to={link.href} className='float-left cursor-pointer' onClick={() => {navigateTo(link.href)}}>{link.name}</Link>
                )
              })
            }
          </div>
          <br />
          <div>
            <p id="slide-bottom" className='fixed w-full top-10 text-center xl:text-6xl text-3xl font-mono font-bold pt-[30vh]'>Avenir de Saxon</p>
          </div>
        </div>
        <img className="object-cover h-[100vh] w-full absolute" src="./img/homepage.jpg" alt=""/>
        <HiArrowSmDown onClick={goToContent} className='h-[60px] w-[60px] text-white absolute z-50 bottom rounded-full absolute cursor-pointer bg-[rgba(0,0,0,.8)] bottom-[60px] animate-bounce' style={{left: "calc( 50vw - 30px )"}} />
      </div>
      <div id="content"></div>
      <div>
        <div className='xl:grid xl:grid-cols-2 gap-20 my-10 xl:mx-40 mx-2 '>
          <img alt="" id="slide-right-animation" src="./img/ema.jpg" className='hidden xl:block rounded-lg'/>
          <div id="slide-left-animation">
              <h1 className='xl:text-3xl text-xl font-bold text-center'>L'Ecole de musique de l'Avenir de Saxon</h1>
              <p className='mt-5 text-gray-800 text-center italic xl:text-base text-sm'>Vous voulez rejoindre l'avenir de Saxon? L'avenir de saxon accompagne ses jeunes dans leur formation musicale. </p>
              
              <button
                className="xl:mt-[60px] mt-[15px] mx-auto rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm flex"
                onClick={() => {navigateTo("ema")}}
              >
                En savoir plus
              </button>
          </div>
        </div>  
        <div className='bg-primary-600 text-white py-10 xl:px-40 px-2 text-3xl'>
          <div id='fade-in-animation'>
            <p className='xl:text-left text-center'>Agenda</p>
            <p className='text-gray-200 xl:text-lg xl:text-left text-sm text-center italic'>Venez nous rencontrer lors d'un de nos prochains évènements</p>
            <div className='overflow-x-auto mt-10 xl:grid xl:grid-cols-3 gap-[50px]'>
              {
                getNextEvent().map((event) => {
                  return (
                    <div className='bg-white text-black rounded-lg p-5 mb-2' key={event?.id}>
                      <div className='sm:flex justify-between'>
                        <p className='xl:text-xl text-lg'>{event?.title} </p>
                        <p className='xl:text-sm text-xs bg-primary-600 py-1 px-3 text-white rounded-lg mb-1 inline-table align-middle'>{event?.date}</p>
                      </div>                      
                      <p className='xl:text-lg text-sm text-gray-800 italic mt-5'>{event?.description}</p>
                      <button onClick={() => {navigateTo("events/" + event?.id)}}className='underline text-gray-800 xl:text-base text-sm xl:mt-5 mt-2'>En savoir plus</button>
                    </div>
                  )
                })
              }
            </div>
            <button onClick={() => {navigateTo("agenda")}} className='mt-10 xl:text-lg text-sm underline xl:mx-0 mx-auto flex'>Voir tout l'agenda</button>
          </div>
        </div>
      </div>
      <div id="parallax" className='py-[30px] xl:py-[125px] xl:px-40 px-2'>
        <div className='text-white p-10 xl:text-3xl text-xl backdrop-brightness-50 rounded-lg h-[250px]'>
          <p className='text-center'>Retrouvez nous sur les réseaux sociaux</p>
          <div className='grid grid-cols-2 mt-10'>
            <a href={instagram} className='text-center flex space-x-4 m-auto cursor-pointer'>
              <svg className="h-9 w-10" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
              </svg>
              <span className='xl:block hidden'>Instagram</span>
            </a>
            <a href={facebook} className='text-center flex space-x-4 m-auto cursor-pointer'>
              <svg className="h-9 w-10" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
              </svg>
              <span className='xl:block hidden'>Facebook</span>
            </a>
          </div>
        </div>
      </div>
      <div className="xl:mx-40 mx-2 py-10">
        <p className='xl:text-3xl text-xl font-bold'>Photos <span className='cursor-pointer xl:text-lg text-sm text-gray-800 underline ml-5' onClick={() => {navigateTo("album")}}>Voir toutes les photos</span></p>
        <div className='overflow-x-auto'>
          <div className="flex xl:w-[3000px] w-[2000px] mt-5">
            {                   
                tenPhotos.map((photo) => {
                    return (
                      <div key={photo.url} className="">
                          <img className="xl:h-[200px] h-[130px] w-auto rounded-lg mx-5" src={photo.url} alt="" />
                      </div>
                    )
                }) 
            }
          </div>
        </div>
      </div>
      <div className='bg-primary-600 xl:py-10 py-1 text-white'>
        <div className='xl:grid xl:grid-cols-2 gap-20 my-10 xl:mx-40 mx-2 '>
          <div id="slide-right-animation-2">
              <h1 className='xl:text-3xl text-xl font-bold text-center'>L'Histoire de l'Avenir de Saxon</h1>

              <img alt="" src="./img/history/photo1906.png" className='rounded-lg xl:hidden mt-5'/>
              <p className='mt-5 text-gray-200 italic text-center xl:text-base text-sm'>Venez découvrir l'Histoire de la fanfare de l'Avenir de Saxon en image depuis 1905.</p>
              
              <button
                className="xl:mt-[60px] mt-[15px] mx-auto px-3 py-2 text-sm font-semibold text-gray-200 hover:text-white flex underline text-center w-fit cursor-pointer"
                onClick={() => {navigateTo("history")}}
              >
                En savoir plus
              </button>
          </div>
          <img alt="" id="slide-left-animation-2" src="./img/history/photo1906.png" className='rounded-lg xl:block hidden'/>
        </div>
      </div>
      <div className='xl:px-40 px-2 py-10 text-center xl:text-xl text-base'>
        <button onClick={() => {navigateTo("contact")}} className='underline'>Pour toutes autres informations, prendre contact avec la personne concernée.</button>
      </div>
      
      <hr className="w-full h-[0.15rem] bg-gray-300 border-0 " />
      <footer className="bg-white">
          <div className="mx-auto max-w-7xl overflow-hidden px-6 py-5 lg:px-8">
              <nav className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
              {navigation.main.map((item) => (
                  <div key={item.name} className="pb-6">
                    <a href={item.href} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                        {item.name}
                    </a>
                  </div>
              ))}
              </nav>
              <div className="mt-10 flex justify-center space-x-10">
              {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
              ))}
              </div>
              <p className="mt-10 text-center text-xs leading-5 text-gray-500">
              &copy; 2023 Avenir de Saxon
              </p>
          </div>
      </footer>
    </div>
};
  
export default Home;