import { Outlet, Link} from "react-router-dom";
import { facebook, instagram } from "../utils/resaux";
let navbarVisible = false;

function toogleNavbar(event) {
  if(navbarVisible){
    hideNavbar();
  } else {
    displayNavbar();
  }
  event.stopPropagation();
}

function displayNavbar(){
  const navbar = document.getElementById("navbar-default");
  navbar.classList.remove("hidden");
  navbarVisible = true;
}

function hideNavbar(){
  const navbar = document.getElementById("navbar-default");
  navbar?.classList?.add("hidden");
  navbarVisible = false;
}

document.onclick = function (e) {
  if(e.target?.id != "btn-toogle-navbar") hideNavbar();
}

function linkClick(){
  window.scrollTo(0,0);
}

const navigation = {
    main: [
      { name: 'Accueil', href: '' },
      { name: 'Ecole de musique', href: 'ema' },
      { name: 'Histoire', href: 'history' },
      { name: 'Membres actifs', href: 'membres_actifs' },
      { name: 'Membres d\'honneur', href: 'membres_honneur' },
      { name: 'Agenda', href: 'agenda' },
      { name: 'Album', href: 'album' },
      { name: 'Contact', href: 'contact' },
      { name: 'Se connecter', href: 'login' },
    ],
    social: [
      {
        name: 'Facebook',
        href: facebook,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Instagram',
        href: instagram,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      } 
    ],
  }

const Layout = () => {
  require("./index.css")
  document.title = "Avenir de Saxon"
    
  return (
    <div className="bg-white">
        <nav className="border-sky-900 pt-2 pb-2 sm:px-4 py-2.5 w-full bg-white fixed">
            <div className="container flex flex-wrap items-center justify-between mx-auto">
                <Link to="" className="flex">
                    <img src="/logo192.png" className="h-10 ml-3 mr-3" alt="" />
                    <span className="self-center text-xl font-semibold whitespace-nowrap">Avenir de Saxon</span>
                </Link>
                <button onClick={toogleNavbar} id="btn-toggle-navbar" type="button" className="inline-flex items-center p-2 ml-3 mr-5 text-sm text-gray-500 rounded-lg xl:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200" aria-controls="navbar-default" aria-expanded="false">
                  <span className="sr-only">Open main menu</span>
                  <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                </button>
                <div className="hidden w-full xl:block xl:w-auto absolute top-[50px] left-[-50%] translate-x-1/2 xl:transform-none xl:static xl:left-0" style={{zIndex: "99"}} id="navbar-default">
                  <ul className="bg-white flex flex-col p-4 mt-3 border border-gray-100 rounded-lg xl:flex-row xl:space-x-8 xl:space-y-0 xl:mt-0 xl:text-sm xl:font-medium xl:border-0 text-center text-lg font-medium space-y-4">
                      {
                        navigation.main.map((item) => {
                          return (
                            <li key={item.href}>
                                <Link onClick={linkClick} to={item.href} className="xl:text-base text-sm cursor-pointer">{item.name}</Link>
                            </li>
                          )
                        })
                      }
                  </ul>
                </div>
            </div>
        </nav>
        <div className={"sm:min-h-[calc(100vh-275px)] min-h-[calc(100vh-94px)] bg-white pt-[70px]"}>
            <Outlet />
        </div>
        <hr className="w-full h-[0.15rem] bg-gray-300 border-0 " />
        <footer className="bg-white">
            <div className="mx-auto max-w-7xl overflow-hidden px-6 py-5 xl:px-8">
                <nav id="nav" className="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
                {navigation.main.map((item) => (
                    <div key={item.name} className="pb-6">
                    <a href={item.href} className="xl:text-sm text-xs leading-6 text-gray-600 hover:text-gray-900">
                        {item.name}
                    </a>
                    </div>
                ))}
                </nav>
                <div className="mt-10 flex justify-center space-x-10">
                {navigation.social.map((item) => (
                    <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only xl:text-normal text-sm">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                ))}
                </div>
                <p className="mt-10 text-center text-xs leading-5 text-gray-500">
                &copy; 2023 Avenir de Saxon
                </p>
            </div>
        </footer>

    </div>
  )
};

export default Layout;