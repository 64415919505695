export default function MultiCheck(props) {
    const {label, required, disabled, elements, value, onChange, id} = props;

    const handleChange = (e, key) => {
        const newValue = [...value]
        if(newValue.includes(key)){
            newValue.splice(newValue.indexOf(key), 1);
        } else {
            newValue.push(key);
        }
        onChange({ target: { id: id, value: newValue}});
        
    }

    return (
        <fieldset>
            {label != undefined ? <label className={"block mb-2 text-sm font-medium " + (disabled?"text-gray-500":"text-gray-900") + " " + (disabled?"dark:text-slate-400":"dark:text-gray-300")}>{label + (required ? " *" : "")}</label> : null}  
            <div className="flex flex-col">
                {
                    elements.map((element, index) => {
                        return (
                            <div key={index} className="relative flex items-start mr-4">
                                <div className="flex h-6 items-center">
                                    <input onChange={(e) => {handleChange(e, element.key)}} id={element.key} checked={value?.includes(element.key)} aria-describedby={element.key + "-description"} name={element.key} type="checkbox" className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600" />
                                </div>
                                <div className="ml-3 text-sm leading-6">
                                    <label htmlFor={element.key} className="font-medium text-gray-900 dark:text-gray-100">{element.label}</label>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </fieldset>  
    )
}