import { useEffect, useState } from "react";
import Loto from "./Loto";
import { useParams } from "react-router-dom";
import { getDate } from "../api/agenda";
import Event404 from "../pages/Event404";
import Spectacle from "./Spectacle";

const defaultForm = {
    template: null,
    forms: {}
}

export function allTemplates(){
    return [
        {key: "Loto", id: "loto", component: Loto, forms: Loto.forms()},
        {key: "Spectacle", id: "spectacle", component: Spectacle, forms: Spectacle.forms()}
    ]
}

function getTemplateById(id){
    console.log(id);

    for (const t of allTemplates()){
        if(t.id == id) return t;
    }

    return null;
}

export default function Template(){
    const {id} = useParams();

    const [formValues, setFormValues] = useState(defaultForm)

    useEffect(() => {
        getDate(id).then((data) => {
            setFormValues({
                ...data.data,
            });
        });
    }, [id]);

    return getTemplateById(formValues.template) ? getTemplateById(formValues.template).component.render(formValues.forms) : <Event404 />;
}