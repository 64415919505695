import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteMembreHonneur, getMembreHonneur, saveMembreHonneur } from "../../../api/membreHonneurs";
import Success from "../../../component/alert/Success";
import { ConfirmModal } from "../../../component/ConfirmModal";
import InputText from "../../../component/input/InputText";
import InputChecked from "../../../component/input/InputChecked"
import InputSelect from "../../../component/input/InputSelect"
import Breadcrumb from "../../../component/Breadcrumb";

const defaultForm = {
    id: 0,
    name: "",
    role: 0,
    date: 0,
    alive: true,
    village: "",
}

const errorFormDefault = {
    name: {},
    role: {},
    date: {},
    alive: {},
    village: {},
}

export function MembreHonneurForm(props){
    const {id} = useParams();

    const navigate = useNavigate();

    useEffect(() => {

        if(id !== "0") {
            getMembreHonneur(id).then((data) => {
                setFormValues({
                    ...data.data,
                });
            })
        }
    }, [id]);

    const [formValues, setFormValues] = useState(defaultForm)
    const [errorForm, setErrorForm] = useState(errorFormDefault);
    const [deleteModal, setDeleteModal] = useState(false);
    const [success, setSuccess] = useState({show: false, message: ""});

    function hanldeKeyDown(e){
        if(e.keyCode === 27){
            setDeleteModal(false);
        }
    }

    function showDeleteModal(e){
        e.preventDefault();
        setDeleteModal(true);
    }

    function performDelete(){
        setDeleteModal(false);
        deleteMembreHonneur(formValues.id).then(() => {
            navigate("../membreHonneurs");
        })
    }

    function checkForm(){
        let tempErrorForm = errorFormDefault;
        let error = false;
        const requiredFields =  ["name", "village", "date"];
        for(const requiredField of requiredFields){
            if(formValues[requiredField] == ""){
                tempErrorForm = {
                    ...tempErrorForm,
                    [requiredField] : {
                        error: true,
                        message: "Champ obligatoire"
                    }
                }
                error = true;
            }
        }
        setErrorForm(tempErrorForm);
        return !error;
    }

    function handleSave(e){
        e.preventDefault();
        if(checkForm()){
            saveMembreHonneur(id, formValues).then((data) => {
                navigate("../membreHonneurs");
            }).catch((error) => {
                for(const field in error.response.data){
                    setErrorForm({
                        ...errorFormDefault,
                        [field]: {
                            error: true,
                            message: error.response.data[field]
                        }
                    })
                }
            })
        }
    }

    function handleChange(e) {
        const {id, value} = e.target;
        setFormValues({
            ...formValues,
            [id]: value
        })
    }

    return (
        <div onKeyDown={hanldeKeyDown}>
            <Breadcrumb className="pl-5" history={[{url: "../membreHonneurs", key: "Membres d'honneur"}]} current={id==0?"Nouveau membre d'honneur":formValues.name}/>
            <form className="p-1 sm:p-10 pt-5">             
                <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                    <h2 className="text-2xl font-bold dark:text-white">{id==="0"?"Nouveau membre d'honneur":"Modification du membre d'honneur"}</h2>
                    <div className="mb-6 col-start-1">
                        <InputText value={formValues.name} id="name" label="Nom" onChange={handleChange} error={errorForm.name} required/>
                    </div>
                    <div className="mb-6">
                        <InputSelect options={[{id: 0, key: "Membre d'honneur"}, {id: 1, key: "Président d'honneur"}, {id: 2, key: "Directeur d'honneur"}]} id="role" onChange={handleChange} label="Roles" error={errorForm.role} value={formValues.role} required></InputSelect>                        
                    </div>
                    <div className="mb-6">
                        <InputText value={formValues.village} id="village" label="Village" onChange={handleChange} error={errorForm.village} required/>
                    </div>
                    <div className="mb-6">
                        <InputText type="number" value={formValues.date} id="date" label="Année" onChange={handleChange} error={errorForm.date} required/>
                    </div>
                    <div className="mb-6">
                        <InputChecked value={formValues.alive} id="alive" label="En vie" onChange={handleChange} error={errorForm.alive}/>
                    </div>
                </div>

                <div className="grid md:grid-cols-4 md:gap-6">
                    <button onClick={handleSave} className="mb-6 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enregistrer</button>
                    {id==="0"?<></>:<button onClick={showDeleteModal} className="mb-6 md:mb-0 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Supprimer</button>}
                </div>

            </form>
            <ConfirmModal isShowed={deleteModal} hide={() => {setDeleteModal(false)}} valid={performDelete} />
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}