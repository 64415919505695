import { useEffect, useRef, useState } from 'react'

export default function WeekView(props) {
  const editEvent = props.editEvent
  const addEvent = props.addEvent
  const container = useRef(null)
  const containerNav = useRef(null)
  const containerOffset = useRef(null)
  const today = new Date();

  const [selectedDay, setSelectedDay] = useState(today.getDay())

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = 10 * 60
    container.current.scrollTop =
      ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
        currentMinute) /
      1440
  }, [])

  const currentDate = new Date(props.currentDate.year, props.currentDate.month, props.currentDate.day);
  let mondayDate = new Date(currentDate)
  mondayDate.setDate(mondayDate.getDate() - mondayDate.getDay() + 1)

  function getCol(event){
    const tempDate = new Date(event.dateStart)
    let eventDate = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate())
    if(event.repeat != 0){
      if(event.stopRepeat != null){
        const stopRepeatDate = new Date(event.stopRepeat);
        const nextMonday = new Date(mondayDate);
        nextMonday.setDate(nextMonday.getDate() + 7);
        if(stopRepeatDate <= nextMonday) return 0;
      } 
    }
    if(event.repeat == 1) {
      return tempDate.getDay();
    } else if(event.repeat == 2){
      const iDate = new Date(mondayDate)
      for(let i = 0; i < 7; i++){
        if(iDate.getDate() == tempDate.getDate()) return i + 1;
        iDate.setDate(iDate.getDate() + 1)
      }
    }
    let col = (eventDate - mondayDate) / 1000 / 3600 / 24
    col++;
    if (col < 0) col = 0;
    if (col > 7) col = 0;
    return col;
  }

  function getDayDate(date, delta){
    let tempDate = new Date(date);
    tempDate.setDate(tempDate.getDate() + delta)
    return (tempDate.getDate() < 10 ? "0" + tempDate.getDate() : tempDate.getDate());
  }
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function isToday(date, delta){
    let tempDate = new Date(date);
    tempDate.setDate(tempDate.getDate() + delta)
    const today = new Date();
    return tempDate.getFullYear() == today.getFullYear() && tempDate.getMonth() == today.getMonth() && tempDate.getDate() == today.getDate();
  }

  function isSelectedDay(event){
    const tempDate = new Date(event.dateStart)
    return tempDate.getDay() == selectedDay;
  }

  function changeSelectedDay(day){
    setSelectedDay(day);
  }

  function getTime(event){
    return event.timeStart + " - " + event.timeEnd
  }

  function sendEditEvent(e, event, date){
    e.stopPropagation();
    editEvent(event, date)
  }

  function sendAddEvent(e, col, hour){
    const day = new Date(mondayDate)
    day.setDate(day.getDate() + col + 1);
    const date = day.toISOString().slice(0, 10);
    addEvent(date, hour + ":00");
  }

  return (
    <div className="flex flex-col" style={{height:"calc(100vh - 250px)", overflow: 'auto'}}>
      <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white dark:bg-gray-900">
        <div style={{ width: '165%' }} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
          <div
            ref={containerNav}
            className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8 dark:bg-gray-900"
          >
            <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
              <button type="button" onClick={() => {changeSelectedDay(1)}} className="flex flex-col items-center pb-3 pt-2">
                L <span className={classNames((selectedDay == 1)?"rounded-full bg-blue-600 text-white":"", isToday(mondayDate, 0) ? "text-blue-600" : "text-gray-900 dark:text-gray-100" ,"mt-1 flex h-8 w-8 items-center justify-center font-semibold")}>{getDayDate(mondayDate, 0)}</span>
              </button>
              <button type="button" onClick={() => {changeSelectedDay(2)}} className="flex flex-col items-center pb-3 pt-2">
                M <span className={classNames((selectedDay == 2)?"rounded-full bg-blue-600 text-white":"", isToday(mondayDate, 1) ? "text-blue-600" : "text-gray-900 dark:text-gray-100" ,"mt-1 flex h-8 w-8 items-center justify-center font-semibold")}>{getDayDate(mondayDate, 1)}</span>
              </button>
              <button type="button" onClick={() => {changeSelectedDay(3)}} className="flex flex-col items-center pb-3 pt-2">
                M <span className={classNames((selectedDay == 3)?"rounded-full bg-blue-600 text-white":"", isToday(mondayDate, 2) ? "text-blue-600" : "text-gray-900 dark:text-gray-100" ,"mt-1 flex h-8 w-8 items-center justify-center font-semibold")}>{getDayDate(mondayDate, 2)}</span>
              </button>
              <button type="button" onClick={() => {changeSelectedDay(4)}} className="flex flex-col items-center pb-3 pt-2">
                J <span className={classNames((selectedDay == 4)?"rounded-full bg-blue-600 text-white":"", isToday(mondayDate, 3) ? "text-blue-600" : "text-gray-900 dark:text-gray-100" ,"mt-1 flex h-8 w-8 items-center justify-center font-semibold")}>{getDayDate(mondayDate, 3)}</span>
              </button>
              <button type="button" onClick={() => {changeSelectedDay(5)}} className="flex flex-col items-center pb-3 pt-2">
                V <span className={classNames((selectedDay == 5)?"rounded-full bg-blue-600 text-white":"", isToday(mondayDate, 4) ? "text-blue-600" : "text-gray-900 dark:text-gray-100" ,"mt-1 flex h-8 w-8 items-center justify-center font-semibold")}>{getDayDate(mondayDate, 4)}</span>
              </button>
              <button type="button" onClick={() => {changeSelectedDay(6)}} className="flex flex-col items-center pb-3 pt-2">
                S <span className={classNames((selectedDay == 6)?"rounded-full bg-blue-600 text-white":"", isToday(mondayDate, 5) ? "text-blue-600" : "text-gray-900 dark:text-gray-100" ,"mt-1 flex h-8 w-8 items-center justify-center font-semibold")}>{getDayDate(mondayDate, 5)}</span>
              </button>
              <button type="button" onClick={() => {changeSelectedDay(7)}} className="flex flex-col items-center pb-3 pt-2">
                D <span className={classNames((selectedDay == 7)?"rounded-full bg-blue-600 text-white":"", isToday(mondayDate, 6) ? "text-blue-600" : "text-gray-900 dark:text-gray-100" ,"mt-1 flex h-8 w-8 items-center justify-center font-semibold")}>{getDayDate(mondayDate, 6)}</span>
              </button>
            </div>

            <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid dark:bg-gray-900 dark:text-gray-300 dark:divide-gray-700 dark:border-gray-700">
              <div className="col-end-1 w-7" />
              <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  Lun <span className={classNames(isToday(mondayDate, 0)?"ml-1.5 flex w-8 rounded-full bg-blue-600 text-white":"ml-1 text-gray-900 dark:text-gray-100","items-center justify-center font-semibold")}>{getDayDate(mondayDate, 0)}</span>
                </span>
              </div>
              <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  Mar <span className={classNames(isToday(mondayDate, 1)?"ml-1.5 flex  w-8 rounded-full bg-blue-600 text-white":"ml-1 text-gray-900 dark:text-gray-100","items-center justify-center font-semibold")}>{getDayDate(mondayDate, 1)}</span>
                </span>
              </div>
              <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  Mer <span className={classNames(isToday(mondayDate, 2)?"ml-1.5 flex  w-8 rounded-full bg-blue-600 text-white":"ml-1 text-gray-900 dark:text-gray-100","items-center justify-center font-semibold")}> {getDayDate(mondayDate, 2)} </span>
                </span>
              </div>
              <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  Jeu <span className={classNames(isToday(mondayDate, 3)?"ml-1.5 flex  w-8 rounded-full bg-blue-600 text-white":"ml-1 text-gray-900 dark:text-gray-100","items-center justify-center font-semibold")}>{getDayDate(mondayDate, 3)}</span>
                </span>
              </div>
              <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  Ven <span className={classNames(isToday(mondayDate, 4)?"ml-1.5 flex  w-8 rounded-full bg-blue-600 text-white":"ml-1 text-gray-900 dark:text-gray-100","items-center justify-center font-semibold")}>{getDayDate(mondayDate, 4)}</span>
                </span>
              </div>
              <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  Sam <span className={classNames(isToday(mondayDate, 5)?"ml-1.5 flex  w-8 rounded-full bg-blue-600 text-white":"ml-1 text-gray-900 dark:text-gray-100","items-center justify-center font-semibold")}>{getDayDate(mondayDate, 5)}</span>
                </span>
              </div>
              <div className="flex items-center justify-center py-3">
                <span className="flex items-baseline">
                  Dim <span className={classNames(isToday(mondayDate, 6)?"ml-1.5 flex  w-8 rounded-full bg-blue-600 text-white":"ml-1 text-gray-900 dark:text-gray-100","items-center justify-center font-semibold")}>{getDayDate(mondayDate, 6)}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-auto overflow-auto">
            <div className="sticky left-0 z-10 w-7 flex-none bg-white ring-1 ring-gray-100 dark:bg-gray-900 dark:ring-gray-700" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:divide-gray-800"
                style={{ gridTemplateRows: 'repeat(24, minmax(2rem, 1fr))' }}
              >
                <div ref={containerOffset} className="row-end-1 h-3"></div>
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    00h
                  </div> 
                </div>
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    01h
                  </div>
                </div>
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    02h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    03h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    04h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    05h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    06h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    07h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    08h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    09h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    10h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    11h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    12h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    13h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    14h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    15h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    16h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    17h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    18h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    19h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    20h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    21h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    22h
                  </div>
                </div>
                
                <div>
                  <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-7 pr-2 text-right text-xs leading-5 text-gray-400">
                    23h
                  </div>
                </div>
                
              </div>

              {/* Vertical lines */}
              <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7 dark:divide-gray-700">
                <div className="col-start-1 row-span-full" />
                <div className="col-start-2 row-span-full" />
                <div className="col-start-3 row-span-full" />
                <div className="col-start-4 row-span-full" />
                <div className="col-start-5 row-span-full" />
                <div className="col-start-6 row-span-full" />
                <div className="col-start-7 row-span-full" />
                <div className="col-start-8 row-span-full w-8" />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
                style={{ gridTemplateRows: '1.75rem repeat(288, minmax(0, 1fr)) auto' }}
              >
                {
                  Array.apply(0, Array(7)).map(function(x, i) {
                    return (
                      <>
                        {
                          Array.apply(0, Array(24)).map(function(x, j) {
                            return (
                              <div key={"k"+i+j} onClick={(e) => sendAddEvent(e, i, j)} className={"cursor-pointer"} style={{ gridRow: 12*j + " / span " + (60/5)}}></div>
                            )

                          })
                        }
                      </>
                    )
                  })                  
                }

                {
                    props.events.map((event) => {
                        const col = getCol(event);
                        if(col == 0){
                          return null
                        }
                        const start = event.timeStart.slice(0, 2) * 12 + (Math.floor(event.timeStart.slice(3, 5) / 5))
                        const startDate = new Date(event.dateStart + ":" + event.timeStart);
                        const endDate = new Date(event.dateStart + ":" + event.timeEnd)
                        const duration = (endDate - startDate) / 1000 / 60 //Duration in minute
                        const date = new Date(mondayDate)
                        date.setDate(date.getDate() + col);
                        return (                            
                            <li onClick={(e) => {sendEditEvent(e, event, date.toISOString().slice(0, 10))}} key={event.id + date.toISOString} className={classNames(isSelectedDay(event) ? "flex" : "hidden sm:flex", "relative mt-px col-start-1 sm:col-start-" + col + "")} style={{ gridRow: start + " / span " + ((duration/5)) }}>
                                <button
                                    className={"pointer group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-" + (event.user.color==null ? "gray" : event.user.color) + "-100 p-1 px-2 text-xs leading-5 hover:bg-" + (event.user.color==null ? "gray" : event.user.color) + "-100"}
                                >
                                <p className={"order-1 font-semibold text-" + (event.user.color==null ? "gray" : event.user.color) + "-800"}>{event.title}</p>
                                <p className={"text-" + (event.user.color==null ? "gray" : event.user.color) + "-500 group-hover:text-" + (event.user.color==null ? "gray" : event.user.color) + "-800"}>
                                    <time>{getTime(event)}</time>
                                </p>
                                </button>
                            </li>
                        )
                    })
                }
              </ol>

              <div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
