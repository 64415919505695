import { faCross } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalendarIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { getAllMembreHonneurs } from "../api/membreHonneurs";


const HonorMember = () => {
    const [presidents, setPresidents] = useState([]);
    const [directions, setDirections] = useState([]);
    const [membres, setMembres] = useState([]);


    function loadMembreHonneurs() {
        getAllMembreHonneurs().then((data) => {
            let d = []
            let p = []
            let mem = []
            for(const m of data.data){
                if(m.role == 0) mem.push(m)
                if(m.role == 1) p.push(m)
                if(m.role == 2) d.push(m)
            }
            setPresidents(p)
            setDirections(d)
            setMembres(mem)
        })
    }

    useEffect (() => {
        loadMembreHonneurs();
    }, [])

    return <div className="m-auto p-2 max-w-[1400px]">  
        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Membres</h1>
            <small className="font-semibold text-gray-500 text-lg md:ml-5 md:text-4xl">d'honneur</small>
        </div>    
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 grid-rows-1 mt-10">
            <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
                <time className=" md:text-lg text-sm font-semibold text-gray-900">Président d'honneur</time>
                <div className="mt-3 grid grid-cols-1 lg:grid-cols-2">
                    {
                        presidents.map((president) => {
                            return (
                                <div key={president.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                    <img className="w-12 h-12 mb-3 mr-3 rounded-full sm:mb-0" src="/img/pp/anonym.jpg" alt=""/>
                                    <div className="text-gray-600">
                                        <div className="ml-1 font-medium text-gray-900 md:text-normal text-sm">{(president.alive)?<></>:<span className="mr-2"><FontAwesomeIcon icon={faCross} style={{color: "#57595b",}} /></span>}{president.name} </div>                            
                                        <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                            <CalendarIcon className="h-4 w-6 text-gray-500" />
                                            {president.date}
                                            <MapPinIcon className="h-4 w-6 ml-4 text-gray-500" />
                                            {president.village}
                                        </span> 
                                    </div>
                                </div>
                            )
                        })
                    }                    
                </div>
            </div>
            <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
                <time className=" md:text-lg text-sm font-semibold text-gray-900">Directeurs d'honneur</time>            
                    <div className="mt-3 grid grid-cols-1 lg:grid-cols-2">                        
                        {
                            directions.map((p) => {
                                return (
                                    <div key={p.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                        <img className="w-12 h-12 mb-3 mr-3 rounded-full sm:mb-0" src="/img/pp/anonym.jpg" alt=""/>
                                        <div className="text-gray-600">
                                        <div className="ml-1 font-medium text-gray-900 md:text-normal text-sm">{(p.alive)?<></>:<span className="mr-2"><FontAwesomeIcon icon={faCross} style={{color: "#57595b",}} /></span>}{p.name} </div>                         
                                            <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                                <CalendarIcon className="h-4 w-6 text-gray-500" />
                                                {p.date}
                                                <MapPinIcon className="h-4 w-6 ml-4 text-gray-500" />
                                                {p.village}
                                            </span> 
                                        </div>
                                    </div>
                                )
                            })
                        }      
                    </div>
            </div>
        </div>

        <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
            <time className=" md:text-lg text-sm font-semibold text-gray-900">Membres d'honneur</time>
            <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
                    {membres.map((member) => {
                        return (     
                            <div key={member.name+member.date} className="items-center block m-1 p-3 flex rounded-lg bg-white">
                                <img className="w-12 h-12 mb-3 mr-3 rounded-full sm:mb-0" src="/img/pp/anonym.jpg" alt=""/>
                                <div className="text-gray-600">                                    
                                    <div className="ml-1 font-medium text-gray-900 md:text-normal text-sm">{(member.alive)?<></>:<span className="mr-2"><FontAwesomeIcon icon={faCross} style={{color: "#57595b",}} /></span>}{member.name} </div>                
                                    <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                        <CalendarIcon className="h-4 w-6 text-gray-500" />
                                        {member.date}
                                        <MapPinIcon className="h-4 w-6 ml-4 text-gray-500" />
                                        {member.village}
                                    </span> 
                                </div>
                            </div>
                        ) 
                    })}
            </div>
        </div>
    </div>     
        
};
  
export default HonorMember;