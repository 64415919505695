import { useState } from "react"
import Error from "../component/alert/Error";
import { login } from "../utils/auth";

const formDefault = {
    userName: "",
    password: ""
}

const Login = () => {
    const [formValues, setFormValues] = useState(formDefault)
    const [error, setError] = useState(false)
    
    function handleKeyDown(e){
        if(e.repeat) return;
        if(e.key == "Enter"){
            e.preventDefault();
            //handleConnection();
        }
    }
            
    document.addEventListener("keydown", handleKeyDown);

    function showWrongUserPassword(){
        setError(true);
    }
    
    function handleConnection(){
        login(formValues, showWrongUserPassword);
    }

    function handleChange(event){
        const {id, value} = event.target;
        setFormValues({
            ...formValues,
            [id] : value
        })
    }
    return <div className="flex flex-col items-center justify-center px-6 py-8  mx-auto lg:py-0">
        <div className="w-full mt-10 bg-white rounded-lg shadow sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                    Se connecter
                </h1>
                <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Nom d'utilisateur</label>
                    <input value={formValues.userName} onChange={handleChange} type="text" id="userName" name="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" placeholder="nom d'utilisateur" />
                </div>
                <div>
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Mot de passe</label>
                    <input value={formValues.password} onChange={handleChange} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5" />
                </div>
                <button onClick={handleConnection} className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Se connecter</button>
            </div>
        </div>
        <Error show={error} onClose={() => {setError(false)}} message={"Le nom d'utilisateur ou le mot de passe est incorrect!"}/>
    </div>
};
  
export default Login;