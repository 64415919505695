import { useEffect, useState } from "react";
import { Table } from "../../../component/Table";
import { useNavigate } from "react-router-dom";
import { ConfirmModal } from "../../../component/ConfirmModal";
import { ChangePassword } from "../../../component/user/ChangePassword";
import { changePassword, deleteUser, getAllUsers } from "../../../api/users";
import Success from "../../../component/alert/Success";
import Breadcrumb from "../../../component/Breadcrumb";
import { FaPlus } from "react-icons/fa";

export function Users() {
    function loadUsers() {
        getAllUsers().then((data) => {
            setUsers(data.data);
        })
    }

    useEffect (() => {
        loadUsers();
    }, []) 
    const [users, setUsers] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [userId, setUserId] = useState(-1);
    const [success, setSuccess] = useState({show: false, message: ""});
    const navigate = useNavigate();
    const struct = [
        {key: "userName", text: "Utilisateur"},
        {key: "role", text: "Role"},
    ]

    function getRoleComponent(role){
        return(
            <>
            {
                role==undefined?<></>:
                <span className="bg-gray-200 text-gray-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                    {role.key}
                </span>
            }
            </>
        )
    }

    function getData(){
        return users.map((user) => {
            return {
                ...user,
                role: getRoleComponent(user.role)
            }
        })
    }

    function confirmDelete(id) {
        setUserId(id);
        setShowConfirmModal(true);
    }

    function performDelete(){
        if(userId > 0){
            deleteUser(userId).then(() => {
                loadUsers();
                setSuccess({show: true, message: "Suppression effectuée!"});
            })
        }
        setShowConfirmModal(false);
    }

    function showChangePassword(id){
        setShowChangePasswordModal(true)
        setUserId(id);
    }

    function handleChangePassword(password){
        setShowChangePasswordModal(false);
        changePassword(userId, password).then(() => {            
            setSuccess({show: true, message: "Modification du mot de passe effectuée!"});
        })
    }

    const actions = [
        {callback: (id) => {navigate("./"+id)}, text: "Modifier", color: "blue", key: "update"},
        {callback: (id) => {showChangePassword(id)}, text: "Changer le mot de passe", color: "green", key: "editPassword", onlyRightClick: true},
        {callback: confirmDelete, text: "Supprimer", color: "red", key: "delete"}
    ]

    return (    
        <div>
            <Breadcrumb className="pl-5" current="Utilisateurs"/>
            <div className="flex items-end justify-between px-1 sm:px-10 py-5">
                <h2 className="text-2xl sm:text-4xl w-full sm:w-auto text-center font-bold dark:text-white">Utilisateurs</h2> 
                <button type="button" onClick={() => {navigate("./0")}} className="hidden sm:flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Créer</button>   
            </div>

            <div className="m-1 sm:m-10 mb-20 sm:mb-1">
                <Table headers={struct} data={getData(users)} actions={actions}/>
            </div>

            <div onClick={() => {navigate("./0")}} className="sm:hidden h-[60px] w-[60px] bg-blue-500 dark:bg-blue-700 rounded-lg fixed lg:hidden bottom-4 right-4 flex">
                <FaPlus className="text-white h-[40px] w-[40px] m-auto"/>
            </div>
            <ConfirmModal isShowed={showConfirmModal} hide={() => {setShowConfirmModal(false)}} valid={performDelete}/>
            <ChangePassword isShowed={showChangePasswordModal} hide={() => {setShowChangePasswordModal(false)}} valid={handleChangePassword}/>
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}