export function getById(obj, id){
    for(const o of obj){
        if(o.id == id) return o
    }
    return null
}

export function getByKey(obj, key){
    for(const o of obj){
        if(o.key == key) return o
    }
    return null
}