import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllRoles } from "../../../api/roles";
import { changePassword, deleteUser, getUser, saveUser } from "../../../api/users";
import Success from "../../../component/alert/Success";
import { ConfirmModal } from "../../../component/ConfirmModal";
import InputSelect from "../../../component/input/InputSelect";
import InputText from "../../../component/input/InputText";
import { ChangePassword } from "../../../component/user/ChangePassword";
import Breadcrumb from "../../../component/Breadcrumb";
import InputColorSelect from "../../../component/input/InputColorSelect";

const defaultForm = {
    id: 0,
    userName: "",
    password: "",
    confirmPassword: "",
    color: "gray",
    role: 0,
}

const errorFormDefault = {
    userName: {},
    password: {},
    confirmPassword: {}
}

const colors = ["gray", "red", "yellow", "blue", "green", "purple", "orange", "pink"]

export function UserForm(props){
    const {id} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        getAllRoles().then((data) => {
            setRoles(data.data);
        })

        if(id !== "0") {
            getUser(id).then((data) => {
                let color = data.data.color
                if(color == undefined) color = "gray"
                setFormValues({
                    ...data.data,
                    color: color
                });
            })
        }
    }, [id]);

    const [formValues, setFormValues] = useState(defaultForm)
    const [errorForm, setErrorForm] = useState(errorFormDefault);
    const [roles, setRoles] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
    const [success, setSuccess] = useState({show: false, message: ""});

    function hanldeKeyDown(e){
        if(e.keyCode === 27){
            setDeleteModal(false);
            setUpdatePasswordModal(false);
        }
    }

    function showDeleteModal(e){
        e.preventDefault();
        setDeleteModal(true);
    }

    function performDelete(){
        setDeleteModal(false);
        deleteUser(formValues.id).then(() => {
            navigate("../users");
        })
    }

    function showUpdatePasswordModal(e){
        e.preventDefault();
        setUpdatePasswordModal(true);
    }

    function checkForm(){
        let tempErrorForm = errorFormDefault;
        let error = false;
        const requiredFields = formValues.id == 0 ? ["userName", "password", "confirmPassword"] : ["userName"];
        for(const requiredField of requiredFields){
            if(formValues[requiredField] == ""){
                tempErrorForm = {
                    ...tempErrorForm,
                    [requiredField] : {
                        error: true,
                        message: "Champ obligatoire"
                    }
                }
                error = true;
            }
        }
        if(formValues.role == 0){            
            tempErrorForm = {
                ...tempErrorForm,
                role : {
                    error: true,
                    message: "Champ obligatoire"
                }
            }
            error = true;
        }
        if(!tempErrorForm.confirmPassword.error && formValues.id == 0 && formValues.password != formValues.confirmPassword){                      
            tempErrorForm = {
                ...tempErrorForm,
                confirmPassword : {
                    error: true,
                    message: "Le mot de passe de confirmation est différent"
                }
            }
            error = true;
        }
        setErrorForm(tempErrorForm);
        return !error;
    }

    function handleSave(e){
        e.preventDefault();
        if(checkForm()){
            saveUser(id, formValues).then((data) => {
                navigate("../users");
            }).catch((error) => {
                for(const field in error.response.data){
                    setErrorForm({
                        ...errorFormDefault,
                        [field]: {
                            error: true,
                            message: error.response.data[field]
                        }
                    })
                }
            })
        }
    }

    function handleChangePassword(password){
        setUpdatePasswordModal(false);
        changePassword(formValues.id, password).then(() => {            
            setSuccess({show: true, message: "Modification du mot de passe effectuée!"});
        })
    }

    function handleChange(e) {
        const {id, value} = e.target;
        setFormValues({
            ...formValues,
            [id]: value
        })
    }

    return (
        <div onKeyDown={hanldeKeyDown}>
            <Breadcrumb className="pl-5" history={[{url: "../users", key: "Utilisateurs"}]} current={formValues.userName}/>
            <form className="p-1 sm:p-10 pt-5">             
                <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                    <h2 className="text-2xl font-bold dark:text-white">{id==="0"?"Nouvel utilisateur":"Modification de l'utilisateur"}</h2>
                    {
                        id==="0"?<></>:
                        <div className="mb-6 col-start-1">
                            <label htmlFor="id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Id</label>
                            <input type="number" id="id" onChange={handleChange} className="cursor-not-allowed bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={formValues.id} required disabled/>
                        </div>
                    }
                    <div className="mb-6 col-start-1">
                        <InputText value={formValues.userName} id="userName" label="Nom d'utilisateur" onChange={handleChange} error={errorForm.userName} required/>
                    </div>
                    <div className="mb-6">
                        <InputSelect options={[{id: 0, key: "Sélectionner un rôle", disabled: true}, ...roles]} id="role" onChange={handleChange} label="Roles" error={errorForm.role} value={formValues.role} required></InputSelect>                        
                    </div>
                    <div className="mb-6">
                        <InputColorSelect options={colors} id="color" onChange={handleChange} label="Couleur" error={errorForm.color} value={formValues.color}></InputColorSelect>                        
                    </div>
                    {
                        id!=="0"?<></>:
                        <>
                            <div className="mb-6">
                                <InputText value={formValues.password} id="password" label="Mot de passe" onChange={handleChange} error={errorForm.password} required type="password"/>
                            </div>
                            <div className="mb-6">
                                <InputText value={formValues.confirmPassword} id="confirmPassword" label="Confirmation de mot de passe" onChange={handleChange} error={errorForm.confirmPassword} required type="password"/>
                            </div>
                        </>
                    }
                </div>

                <div className="grid md:grid-cols-4 md:gap-6">
                    <button onClick={handleSave} className="mb-6 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enregistrer</button>

                    {id==="0"?<></>:<button onClick={showUpdatePasswordModal} className="mb-6 md:mb-0 text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-4 focus:outline-none focus:ring-yellow-200 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-yellow-400 dark:hover:bg-yellow-500 dark:focus:ring-yellow-800">Changer le mot de passe</button>}
                    {id==="0"?<></>:<button onClick={showDeleteModal} className="mb-6 md:mb-0 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Supprimer</button>}
                </div>

            </form>
            <ConfirmModal isShowed={deleteModal} hide={() => {setDeleteModal(false)}} valid={performDelete} />
            <ChangePassword isShowed={updatePasswordModal} hide={() => {setUpdatePasswordModal(false)}} valid={handleChangePassword}/>
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}