import { Modal, Button } from "flowbite-react"
import { useState } from "react"
import InputText from "../input/InputText"

const defaultFormValues = {
    password: "",
    confirmPassword: ""
}

const errorFormDefault = {
    password: {},
    confirmPassword: {}
}

export function ChangePassword(props) {
    const {isShowed, hide, valid} = props

    const [formValues, setFormValues] = useState(defaultFormValues)
    const [errorForm, setErrorForm] = useState(errorFormDefault);

    function handleKeyDown(e){
        if(isShowed) {
            if(e.key == "Escape"){
                hide();
            }
        }
    }
            
    document.addEventListener("keydown", handleKeyDown);

    function handleChange(e){
        const {id, value} = e.target;
        setFormValues({
            ...formValues,
            [id]: value
        })
    }

    function checkForm(){
        let tempErrorForm = errorFormDefault;
        let error = false;
        const requiredFields = ["password", "confirmPassword"];
        for(const requiredField of requiredFields){
            if(formValues[requiredField] == ""){
                tempErrorForm = {
                    ...tempErrorForm,
                    [requiredField] : {
                        error: true,
                        message: "Champ obligatoire"
                    }
                }
                error = true;
            }
        }

        if(!tempErrorForm.confirmPassword.error && formValues.password != formValues.confirmPassword){                      
            tempErrorForm = {
                ...tempErrorForm,
                confirmPassword : {
                    error: true,
                    message: "Le mot de passe de confirmation est différent"
                }
            }
            error = true;
        }
        setErrorForm(tempErrorForm);
        return !error;
    }

    function close(){
        setFormValues(defaultFormValues);
        setErrorForm(errorFormDefault);
        hide();
    }

    function update(){
        if(checkForm()){
            setFormValues(defaultFormValues);
            setErrorForm(errorFormDefault);
            valid(formValues.password);
        }
    }

    return (
        <Modal
            show={isShowed}
            size="md"
            popup={true}
            onClose={close}
        >
            <Modal.Header>
                <span className="ml-3">Modifier le mot de passe</span>
            </Modal.Header>
            <Modal.Body>
            <div className="text-left grid md:grid-cols-1 md:gap-6 mb-10">
                <div>
                    <InputText type="password" id="password" label="Nouveau mot de passe" error={errorForm.password} value={formValues.password} onChange={handleChange} />
                </div>
                <div>
                    <InputText type="password" id="confirmPassword" label="Confirmer le mot de passe" error={errorForm.confirmPassword} value={formValues.confirmPassword} onChange={handleChange} />
                </div>
            </div>

            <div className="flex items-end justify-between gap-4">                
                <button onClick={update} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Modifier</button>
                <Button
                    color="gray"
                    onClick={close}
                >
                    Annuler
                </Button>
            </div>
            </Modal.Body>
        </Modal>
    )
}