import { faAddressBook, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLink, faLocationDot, faPhone, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { getContact } from "../api/contacts";

const Contact = () => {
    const [contact, setContact] = useState({})

    function loadContact() {
        getContact(1).then((data) => {
            setContact(data.data);
        })
    }

    useEffect (() => {
        loadContact();
    }, []) 

    return <div className="p-2 max-w-[1400px] m-auto">
        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Contact</h1>
        </div>  
        <div href="#" className="p-2 mt-10 mb-10 md:h-[450px] flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row">            
            <iframe
                title="Avenir Saxon"
                className="hidden md:block"
                style={{maxWidth: "90%"}}
                width="550"
                height="450"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCE-FN_B14vqjpDE6Zo7YA52yMpC0vHqVw&q=Rue de Gottefrey 44, 1907 Saxon, Switzerland"
            >
            </iframe>
            <div className="p-4 pt-0 h-full">
                <h5 className="mt-2 mb-10 md:text-3xl text-base font-bold tracking-tight text-gray-900">Informations générales</h5>
                <div>
                    <div className="flex flex-row">
                        <FontAwesomeIcon className="w-5 m-auto ml-0 mr-6" icon={faLocationDot} />
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 ">{contact.address}</p>
                    </div>
                    <div className="flex flex-row">
                        <FontAwesomeIcon className="w-5 m-auto ml-0 mr-6" icon={faEnvelope} />
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 ">{contact.email}</p>
                    </div>
                    <div className="flex flex-row">
                        <FontAwesomeIcon className="w-5 m-auto ml-0 mr-6" icon={faLink} />
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 ">Fédération des fanfares démocrates chrétiennes du centre <a className="text-blue-900" href="https://www.ffdcc.ch">www.ffdcc.ch</a></p>
                    </div>
                    <div className="flex flex-row">
                        <FontAwesomeIcon className="w-5 m-auto ml-0 mr-6" icon={faLink} />
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 ">Fédération des musiques du Valais central <a className="text-blue-900" href="https://www.fmvc.ch">www.fmvc.ch</a></p>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 mb-10">
            <div href="#" className="flex items-center bg-white border border-gray-200 rounded-lg shadow flex-row">                
                <img className="md:h-[150px] h-[100px] mb-3 mr-3 rounded-lg sm:mb-0" src={contact.presidentPhoto ? contact.presidentPhoto : "/img/pp/anonym.jpg"} alt=""/>
                <div className="p-4 pt-0 h-full">
                    <h5 className="my-2 md:text-xl text-base font-bold tracking-tight text-gray-900 text-center">Président</h5>
                    <div>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faAddressBook} /></span>{contact.presidentName}</p>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faPhone} /></span>{contact.presidentPhone}</p>
                    </div>
                </div>
            </div>

            <div href="#" className="flex items-center bg-white border border-gray-200 rounded-lg shadow flex-row">                
                <img className="md:h-[150px] h-[100px] mb-3 mr-3 rounded-lg sm:mb-0" src={contact.directeurPhoto ? contact.directeurPhoto : "/img/pp/anonym.jpg"} alt=""/>
                <div className="p-4 pt-0 h-full">
                    <h5 className="my-2 md:text-xl text-base font-bold tracking-tight text-gray-900 text-center">Directeur</h5>
                    <div>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faAddressBook} /></span>{contact.directeurName}</p>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faPhone} /></span>{contact.directeurPhone}</p>
                    </div>
                </div>
            </div>

            <div href="#" className="flex items-center bg-white border border-gray-200 rounded-lg shadow flex-row">                
                <img className="md:h-[150px] h-[100px] mb-3 mr-3 rounded-lg sm:mb-0" src={contact.responsableEMAPhoto ? contact.responsableEMAPhoto : "/img/pp/anonym.jpg"} alt=""/>
                <div className="p-4 pt-0 h-full">
                    <h5 className="my-2 md:text-xl text-base font-bold tracking-tight text-gray-900 text-center">Ecole de musique</h5>
                    <div>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faAddressBook} /></span>{contact.responsableEMAName}</p>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faPhone} /></span>{contact.responsableEMAPhone}</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
};
  
export default Contact;