import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { deleteMember, getMember, saveMember } from "../../../api/members";
import Success from "../../../component/alert/Success";
import { ConfirmModal } from "../../../component/ConfirmModal";
import InputText from "../../../component/input/InputText";
import Breadcrumb from "../../../component/Breadcrumb";
import MultiCheck from "../../../component/input/Tailwind/MultiCheck";
import InputImage from "../../../component/input/InputImage";
import InputCombo from "../../../component/input/InputCombo";
import {instruments, comiteRoles } from "../../../utils/enum";

export const roles = [
    { key: "musicien", label: "Musicien"},
    { key: "comite", label: "Comité"},
    { key: "direction", label: "Directeur"},
    { key: "filleHonneur", label: "Fille d'honneur"},
    { key: "porteDrapeau", label: "Porte-drapeau"},
    { key: "school", label: "Ecole de musique"}
]

const defaultForm = {
    id: 0,
    name: "",
    year: "",
    roles: [],
    role: "",
    instrument: "",
    schoolInstrument: "",
    professor: "",
    photo: "",
}

const errorFormDefault = {
    name: {},
    year: {},
    schoolInstrument: {},
    professor: {}
}

export function MemberForm(props){
    const {id} = useParams();

    const navigate = useNavigate();

    useEffect(() => {

        if(id !== "0") {
            getMember(id).then((data) => {
                const tempRoles = [];
                let instrument = "";
                let role = "";
                let schoolInstrument = "";
                let professor = "";
                if (data.data.musicien != null){
                    tempRoles.push("musicien");
                    instrument = data.data.musicien.instrument;                    
                }
                if (data.data.comite != null){
                    tempRoles.push("comite");
                    role = data.data.comite.role;
                }
                if (data.data.direction != null){
                    tempRoles.push("direction");
                }
                if (data.data.filleHonneur != null){
                    tempRoles.push("filleHonneur");
                }
                if (data.data.porteDrapeau != null){                 
                    tempRoles.push("porteDrapeau");
                }
                if (data.data.school != null){
                    tempRoles.push("school");
                    schoolInstrument = data.data.school.instrument;
                    professor = data.data.school.professor;
                }
                
                setFormValues({
                    ...data.data,
                    roles: tempRoles,
                    instrument: instrument,
                    professor: professor,
                    schoolInstrument: schoolInstrument,
                    role: role
                });
            })
        }
    }, [id]);

    const [formValues, setFormValues] = useState(defaultForm)
    const [errorForm, setErrorForm] = useState(errorFormDefault);
    const [deleteModal, setDeleteModal] = useState(false);
    const [success, setSuccess] = useState({show: false, message: ""});

    function hanldeKeyDown(e){
        if(e.keyCode === 27){
            setDeleteModal(false);
        }
    }

    function showDeleteModal(e){
        e.preventDefault();
        setDeleteModal(true);
    }

    function performDelete(){
        setDeleteModal(false);
        deleteMember(formValues.id).then(() => {
            navigate("../members");
        })
    }

    function checkForm(){
        let tempErrorForm = errorFormDefault;
        let error = false;
        const requiredFields = ["name"];
        const musicientRequiredFields = ["instrument"];
        const schoolRequiredFields = ["schoolInstrument", "professor"];
        const comiteRequiredFields = ["role"];

        for(const requiredField of requiredFields){
            if(formValues[requiredField] == ""){
                tempErrorForm = {
                    ...tempErrorForm,
                    [requiredField] : {
                        error: true,
                        message: "Champ obligatoire"
                    }
                }
                error = true;
            }
        }

        if (formValues.roles.includes("musicien")){
            for(const requiredField of musicientRequiredFields){
                if(formValues[requiredField] == ""){
                    tempErrorForm = {
                        ...tempErrorForm,
                        [requiredField] : {
                            error: true,
                            message: "Champ obligatoire"
                        }
                    }
                    error = true;
                }
            }
        }
        
        if (formValues.roles.includes("comite")){
            for(const requiredField of comiteRequiredFields){
                if(formValues[requiredField] == ""){
                    tempErrorForm = {
                        ...tempErrorForm,
                        [requiredField] : {
                            error: true,
                            message: "Champ obligatoire"
                        }
                    }
                    error = true;
                }
            }
        }

        if (formValues.roles.includes("school")){
            for(const requiredField of schoolRequiredFields){
                if(formValues[requiredField] == ""){
                    tempErrorForm = {
                        ...tempErrorForm,
                        [requiredField] : {
                            error: true,
                            message: "Champ obligatoire"
                        }
                    }
                    error = true;
                }
            }
        }


        setErrorForm(tempErrorForm);
        return !error;
    }

    function handleSave(e){
        e.preventDefault();
        console.log(formValues);
        if(checkForm()){
            saveMember(id, formValues).then((data) => {
                navigate("../members");
            }).catch((error) => {
                for(const field in error.response.data){
                    setErrorForm({
                        ...errorFormDefault,
                        [field]: {
                            error: true,
                            message: error.response.data[field]
                        }
                    })
                }
            })
        }
    }

    function handleChange(e) {
        const {id, value} = e.target;
        setFormValues({
            ...formValues,
            [id]: value
        })
    }

    return (
        <div onKeyDown={hanldeKeyDown}>
            <Breadcrumb className="pl-5" history={[{url: "../members", key: "Membres actifs"}]} current={id==0?"Nouveau membre":formValues.name}/>
            <form className="p-1 sm:p-10 pt-5">             
                <div className="grid md:grid-cols-2 md:gap-6 mb-16">
                    <h2 className="text-2xl font-bold dark:text-white">{id==="0"?"Nouveau membre":"Modification du membre"}</h2>
                    <div className="mb-6 col-start-1">
                        <InputText value={formValues.name} id="name" label="Nom" onChange={handleChange} error={errorForm.name} required/>
                    </div>
                    <div className="mb-6">
                        <InputText type="number" value={formValues.year} id="year" label="Année d'adhésion" onChange={handleChange} error={errorForm.year}/>
                    </div>
                    <div className="mb-6">
                        <MultiCheck onChange={handleChange} value={formValues.roles} elements={roles} id = "roles" label="Roles" />
                    </div>
                    <div>
                        <InputImage onChange={handleChange} value={formValues.photo} id="photo" label="Photo"/>
                    </div>
                </div>
                    
                    
                    {
                        !formValues.roles?.includes("musicien") ? <></> :
                        <>
                            <h2 className="w-full text-center border-b border-gray-400 leading-[0.1em] mb-6"><span className="text-gray-600 text-center dark:text-gray-400 p-10 bg-white dark:bg-[#111827]"> Musicien</span></h2>
                            <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                                <div className="mb-6 col-start-1">
                                    <InputCombo value={formValues.instrument} id="instrument" label="Voix" onChange={handleChange} error={errorForm.instrument} required options={instruments}/>
                                </div>
                            </div>
                        </>
                    }                    
                    
                    {
                        !formValues.roles?.includes("school") ? <></> :
                        <>
                            <h2 className="w-full text-center border-b border-gray-400 leading-[0.1em] mb-6"><span className="text-gray-600 text-center dark:text-gray-400 p-10 bg-white dark:bg-[#111827]"> Ecole de musique</span></h2>
                            <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                                <div className="mb-6 col-start-1">
                                    <InputText value={formValues.schoolInstrument} id="schoolInstrument" label="Instrument" onChange={handleChange} error={errorForm.schoolInstrument} required/>
                                </div>
                                <div className="mb-6">
                                    <InputText value={formValues.professor} id="professor" label="Professeur" onChange={handleChange} error={errorForm.professor} required/>
                                </div>
                            </div>
                        </>
                    }
                    {
                        !formValues.roles?.includes("comite") ? <></> :
                        <>
                            <h2 className="w-full text-center border-b border-gray-400 leading-[0.1em] mb-6"><span className="text-gray-600 text-center dark:text-gray-400 p-10 bg-white dark:bg-[#111827]"> Comité</span></h2>
                            <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                                <div className="mb-6 col-start-1">
                                    <InputCombo value={formValues.role} id="role" label="Role au comité" onChange={handleChange} error={errorForm.role} required options={comiteRoles}/>
                                </div>
                            </div>
                        </>
                    }

                <div className="grid md:grid-cols-4 md:gap-6">
                    <button onClick={handleSave} className="mb-6 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enregistrer</button>
                    {id==="0"?<></>:<button onClick={showDeleteModal} className="mb-6 md:mb-0 text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">Supprimer</button>}
                </div>
            </form>
            <ConfirmModal isShowed={deleteModal} hide={() => {setDeleteModal(false)}} valid={performDelete} />
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}