import { useEffect, useState } from "react";
import { getContact, saveContact } from "../../../api/contacts";
import Success from "../../../component/alert/Success";
import InputText from "../../../component/input/InputText";
import Breadcrumb from "../../../component/Breadcrumb";
import InputImage from "../../../component/input/InputImage";

const defaultForm = {
    id: 1,
    name: "",
    role: "",
}

const errorFormDefault = {
    name: {},
    role: {},
}

export function ContactForm(props){
    const id = defaultForm.id

    useEffect(() => {
        getContact(id).then((data) => {
            setFormValues({
                ...data.data,
            });
        })
    }, [id]);

    const [formValues, setFormValues] = useState(defaultForm)
    const [errorForm, setErrorForm] = useState(errorFormDefault);
    const [success, setSuccess] = useState({show: false, message: ""});

    function checkForm(){
        let tempErrorForm = errorFormDefault;
        let error = false;
        const requiredFields =  ["name"];
        for(const requiredField of requiredFields){
            if(formValues[requiredField] == ""){
                tempErrorForm = {
                    ...tempErrorForm,
                    [requiredField] : {
                        error: true,
                        message: "Champ obligatoire"
                    }
                }
                error = true;
            }
        }
        setErrorForm(tempErrorForm);
        return !error;
    }

    function handleSave(e){
        e.preventDefault();
        if(checkForm()){
            saveContact(id, formValues).then((data) => {
                setSuccess({
                    show: true,
                    message: "Informations enregistrées"
                })
            }).catch((error) => {
                for(const field in error.response.data){
                    setErrorForm({
                        ...errorFormDefault,
                        [field]: {
                            error: true,
                            message: error.response.data[field]
                        }
                    })
                }
            })
        }
    }

    function handleChange(e) {
        const {id, value} = e.target;
        console.log(id, value);
        setFormValues({
            ...formValues,
            [id]: value
        })
    }

    return (
        <div>
            <Breadcrumb className="sm:pl-5" current={"Contact"}/>
            <form className="p-1 sm:p-10 pt-5">             
                <div className="grid md:grid-cols-2 md:gap-6 mb-10">
                    <h2 className="text-2xl font-bold dark:text-white">Contact</h2>
                    <h2 className="text-xl font-bold dark:text-white col-start-1 mt-5">Informations générales</h2>
                    <div className="mb-6 col-start-1">
                        <InputText value={formValues.address} id="address" label="Addresse" onChange={handleChange} error={errorForm.address} required/>
                    </div>
                    <div className="mb-6">
                        <InputText value={formValues.email} id="email" label="E-mail" onChange={handleChange} error={errorForm.email} required/>
                    </div>
                    <h2 className="text-xl font-bold dark:text-white col-start-1 mt-5">Informations du président</h2>
                    <div className="mb-6 col-start-1">
                        <InputText value={formValues.presidentName} id="presidentName" label="Nom du président" onChange={handleChange} error={errorForm.presidentName} required/>
                    </div>
                    <div className="mb-6">
                        <InputText value={formValues.presidentPhone} id="presidentPhone" label="N° de téléphone du président" onChange={handleChange} error={errorForm.presidentPhone} required/>
                    </div>
                    <div>
                        <InputImage onChange={handleChange} value={formValues.presidentPhoto} id="presidentPhoto" label="Photo du président"/>
                    </div>
                    <h2 className="text-xl font-bold dark:text-white col-start-1 mt-5">Informations du directeur</h2>
                    <div className="mb-6 col-start-1">
                        <InputText value={formValues.directeurName} id="directeurName" label="Nom du directeur" onChange={handleChange} error={errorForm.directeurName} required/>
                    </div>
                    <div className="mb-6">
                        <InputText value={formValues.directeurPhone} id="directeurPhone" label="N° de téléphone du directeur" onChange={handleChange} error={errorForm.directeurPhone} required/>
                    </div>
                    <div>
                        <InputImage onChange={handleChange} value={formValues.directeurPhoto} id="directeurPhoto" label="Photo du directeur"/>
                    </div>
                    <h2 className="text-xl font-bold dark:text-white col-start-1 mt-5">Informations du responsablde de l'école de de musique</h2>
                    <div className="mb-6 col-start-1">
                        <InputText value={formValues.responsableEMAName} id="responsableEMAName" label="Nom du responsable" onChange={handleChange} error={errorForm.responsableEMAName} required/>
                    </div>
                    <div className="mb-6">
                        <InputText value={formValues.responsableEMAPhone} id="responsableEMAPhone" label="N° de téléphone du responsable" onChange={handleChange} error={errorForm.responsableEMAPhone} required/>
                    </div>
                    <div>
                        <InputImage onChange={handleChange} value={formValues.responsableEMAPhoto} id="responsableEMAPhoto" label="Photo du responsable"/>
                    </div>
                </div>

                <div className="grid md:grid-cols-4 md:gap-6">
                    <button onClick={handleSave} className="mb-6 md:mb-0 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Enregistrer</button>
                </div>

            </form>
            <Success show={success.show} message={success.message} onClose={() => {setSuccess({show: false, message: ""})}}/>
        </div>
    )
}