import axios from 'axios';
import { getAuthFromLocalStorage } from '../utils/auth';

export const URL = window.location.origin.includes("localhost") ? "http://localhost:8000/api" : window.location.origin + "/api";

const auth = getAuthFromLocalStorage();

let errorCallback = null;
let serverMissingCallback = null;

export function setErrorCallback(callback){
    errorCallback = callback;
}

export function setServerMissingCallback(callback){
    serverMissingCallback = callback;
}

export const api = axios.create({
    baseURL: URL,
    headers: {authorization: auth?.token}
})

api.interceptors.response.use(undefined, err => {
    const error = err.response;
    if(error.status == 401){
        if(auth?.token == null){
            document.location.replace("/login");
        } else {
            if(errorCallback == null){
                console.log("display error");
            } else {
                errorCallback();
            }
            return Promise.reject(err);
        }
    } else if(error.status == 0) {
        serverMissingCallback();
    } else {
        return Promise.reject(err);
    }
})