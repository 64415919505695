import { useEffect, useState } from "react";
import { deleteEvent as deleteEventAPI, getAllEvents, saveEvent as saveEventAPI } from "../../api/events";
import { getCurrentUser } from "../../api/users";
import { Calendar } from "../../component/calendar/Calendar";

export function AdminHome(){
    const [events, setEvents] = useState([]);
    const [currentUser, setCurrentUser] = useState(null)

    function loadEvents() {
        getAllEvents().then((data) => {
            setEvents(data.data);
        })
    }

    function loadCurrentUser() {
        getCurrentUser().then((data) => {
            setCurrentUser(data.data);
        })
    }

    useEffect (() => {
        loadEvents();
        loadCurrentUser();
    }, []) 

    function deleteEvent(event, mode){
        deleteEventAPI(event.id, {mode: mode, date: event.dateOfDay}).then(() => {
            loadEvents()
        })
    }
    function saveEvent(event, mode){
        saveEventAPI(event.id, {mode: mode, event: event}).then(() => {
            loadEvents()
        })
    }
    return <div className="px-1 lg:px-5 pt-5">
        <h2 className="lg:text-4xl text-2xl font-bold dark:text-white pb-5">Réservation du local</h2> 
        <div style={{height: "calc(100vh - 200px)"}} className="">
            <Calendar onSave={saveEvent} onDelete={deleteEvent} events={events} currentUser={currentUser}></Calendar>
        </div>
    </div>
}