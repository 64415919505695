import {
  ClockIcon
} from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';
import { BsArrowRepeat } from 'react-icons/bs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function getEventByDate(event, strDate){
  if(event.dateStart == undefined) return false;
  //event.repeat : 0 -> no repeat ; 1 -> weekly ; 2 -> monthly
  if (event.excludeDates != null && event.excludeDates.filter(date => date.date == strDate).length > 0){
    return false;
  }
  if (event.dateStart == strDate) return true;
  if (olderOrEqual(strDate, event.dateStart)) return false;
  if (event.stopRepeat != null && olderOrEqual(event.stopRepeat, strDate)) return false;
  if (event.repeat == 2){
    if (getNumberOfMonth(event.dateStart) == getNumberOfMonth(strDate)) return true;
  } 
  if (event.repeat == 1){
    if (getNumberOfWeek(event.dateStart) == getNumberOfWeek(strDate)) return true;
  } 
}

/**
 * return true if strDate1 is older or equal than strDate2
 * @param {string} strDate1 date format yyyy-mm-dd
 * @param {string} strDate2 date format yyyy-mm-dd
 */
function olderOrEqual(strDate1, strDate2){
  const date1 = new Date(strDate1)
  const date2 = new Date(strDate2)
  return (date1 <= date2)
}

function getNumberOfMonth(strDate){
  return strDate.slice(8, 10)
}

function getNumberOfWeek(strDate){
  const date = new Date(strDate)
  return date.getDay()
}

function getTime(event){
  return event.timeStart + " - " + event.timeEnd
}

export function MonthView(props) {
  const editEvent = props.editEvent
  const addEvent = props.addEvent

  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState()

  const today = new Date();
  const currentDate = props.currentDate

  function loadDays(){
    const loadDays = [];
    let dateAgenda = new Date(currentDate.year, currentDate.month, 1)
    dateAgenda.setDate(dateAgenda.getDate() - ((dateAgenda.getDay()-1) < 0 ? 6 : (dateAgenda.getDay() - 1)))
    //Add days past month
    for(let i = 0; i < (7*6); i++){
      const isToday = (dateAgenda.getDate() == today.getDate() && dateAgenda.getMonth() == today.getMonth());
      const isCurrentMonth = (dateAgenda.getMonth() == currentDate.month);
      const strDate = dateAgenda.getFullYear() + "-" + ((dateAgenda.getMonth()+1<10)? '0' + (dateAgenda.getMonth() + 1) : (dateAgenda.getMonth() + 1)) + "-" + (dateAgenda.getDate()<10?'0' + dateAgenda.getDate() : dateAgenda.getDate())
      let eventsOfDay = props.events.filter((event) => getEventByDate(event, strDate))
      const newDay = {
        date: strDate,
        events: [...eventsOfDay],
        isToday: isToday,
        isCurrentMonth: isCurrentMonth,
      }
      loadDays.push(newDay)
      dateAgenda.setDate(dateAgenda.getDate() + 1)
      if (isToday) setSelectedDay(newDay);
    }
    setDays(loadDays);
  }

  function changeSelectedDay(strDate){
    const day = days.find((day) => {return day.date == strDate})
    setSelectedDay(day);
  }

  function isSelectedDate(date){
    if(date == null) return false;
    if(selectedDay == null) return false;
    return date.date == selectedDay.date;
  }

  function sendEditEvent(e, event, date){
    e.stopPropagation();
    editEvent(event, date)
  }

  function addReservation(date){
    addEvent(date)
  }

  useEffect(()=>{
    loadDays();
  }, [props.events, props.currentDate])

    return ( <div className="lg:flex lg:h-full lg:flex-col">
      
      <div className="border rounded-md lg:flex lg:flex-auto lg:flex-col dark:border-gray-700 p-1">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none dark:bg-gray-700 dark:border-gray-800">
          <div className="bg-white py-2 dark:bg-gray-900 dark:text-white">
            L<span className="sr-only sm:not-sr-only">un</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-900 dark:text-white">
            M<span className="sr-only sm:not-sr-only">ar</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-900 dark:text-white">
            M<span className="sr-only sm:not-sr-only">er</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-900 dark:text-white">
            J<span className="sr-only sm:not-sr-only">eu</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-900 dark:text-white">
            V<span className="sr-only sm:not-sr-only">en</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-900 dark:text-white">
            S<span className="sr-only sm:not-sr-only">am</span>
          </div>
          <div className="bg-white py-2 dark:bg-gray-900 dark:text-white">
            D<span className="sr-only sm:not-sr-only">im</span>
          </div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto dark:text-white dark:bg-gray-700">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {days.map((day) => (
              <div
                key={day.date}
                onClick={() => {addReservation(day.date)}}
                className={classNames(
                  day.isCurrentMonth ? 'bg-white dark:bg-gray-900' : 'bg-gray-50 text-gray-500 dark:bg-gray-800 dark:text-gray-200',
                  'relative px-3 py-2 cursor-pointer'
                )}
              >
                <time
                  dateTime={day.date}
                  className={
                    day.isToday
                      ? 'flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 font-semibold text-white'
                      : day.isCurrentMonth
                        ? 'font-semibold'
                        : undefined
                  }
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
                {day.events.length > 0 && (
                  <ol className="mt-2">
                    {day.events.slice(0, 2).map((event) => (
                      <li key={event.id}>
                        <button onClick={(e) => {sendEditEvent(e, event, day.date)} } className="group flex w-full cursor-pointer">
                          <p className={'flex-none bg-' + (event.user.color==null ? "gray" : event.user.color) + '-400 h-[8px] w-[8px] m-auto mr-[5px] rounded-full'}></p>
                          <p className="flex-auto text-left truncate font-medium text-gray-900 group-hover:text-blue-600 dark:text-gray-100">                            
                            {event.title}
                          </p>
                          <time
                            dateTime={event.datetime}
                            className="ml-3 hidden flex-none text-gray-500 group-hover:text-blue-600 xl:block"
                          >
                            <div className='flex items-center'>
                              {event.repeat == 0 ? <></> : <BsArrowRepeat className='mr-1'></BsArrowRepeat>}
                              {getTime(event)}
                            </div>
                          </time>
                        </button>
                      </li>
                    ))}
                    {day.events.length > 2 && <li className="text-gray-500">+ {day.events.length - 2} more</li>}
                  </ol>
                )}
              </div>
            ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                onClick={() => {changeSelectedDay(day.date)}}
                key={day.date}
                type="button"
                className={classNames(
                  day.isCurrentMonth ? 'bg-white dark:bg-gray-900' : 'bg-gray-50 dark:bg-gray-800',
                  (isSelectedDate(day) || day.isToday) && 'font-semibold',
                  isSelectedDate(day) && 'text-white',
                  !isSelectedDate(day) && day.isToday && 'text-blue-600',
                  !isSelectedDate(day) && day.isCurrentMonth && !day.isToday && 'text-gray-900 dark:text-gray-100',
                  !isSelectedDate(day) && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                  'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10'
                )}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    isSelectedDate(day) && 'flex h-6 w-6 items-center justify-center rounded-full',
                    isSelectedDate(day) && day.isToday && 'bg-blue-600',
                    isSelectedDate(day) && !day.isToday && 'bg-blue-600',
                    'ml-auto'
                  )}
                >
                  {day.date.split('-').pop().replace(/^0/, '')}
                </time>
                <span className="sr-only">{day.events.length} events</span>
                {day.events.length > 0 && (
                  <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                    {day.events.map((event) => (
                      <span key={event.id} className={"mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-" + (event.user.color==null ? "gray" : event.user.color) + "-400"} />
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {selectedDay?.events.length > 0 && (
        <div className="h-[180px] mt-5 px-0 sm:px-6 lg:hidden overflow-auto">
          <ol className="border divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm dark:border-gray-600 dark:divide-gray-500">
            {selectedDay.events.map((event) => (
              <li onClick={(e) => {sendEditEvent(e, event, selectedDay.date)}} key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50 dark:bg-gray-900" >
                <div className="flex-auto">
                  <h2 className="text-sm flex font-semibold leading-7 text-gray-900 dark:text-gray-100 mb-2">
                    {event.title} 
                    {
                      event.user == undefined ? <></> :
                      <span className={"ml-5 px-2 py-1 rounded-lg text-gray-700 text-sm bg-" + event.user.color + "-300"}>{event.user.userName}</span>
                      }
                  </h2>
                  <time dateTime={event.datetime} className="mt-2 flex items-center text-gray-700 dark:text-gray-300">
                    <ClockIcon className="mr-2 h-5 w-5 text-gray-400 dark:text-white" aria-hidden="true" />
                    {getTime(event)}
                  </time>
                </div>
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  )
}
