import { api } from ".";

export function getAllMembreHonneurs(){
    return api.get('/membreHonneurs')
}

export function saveMembreHonneur(id, data){
    if(id == 0){
        return api.post('/membreHonneurs', data)
    } else {
        return api.post('/membreHonneurs/' + id, data)
    }
}

export function getMembreHonneur(id){
    return api.get("/membreHonneurs/" + id);
}

export function deleteMembreHonneur(id) {    
    return api.delete("/membreHonneurs/" + id);
}