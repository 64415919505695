import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { HiOutlineChevronDown } from 'react-icons/hi'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const className = "pt-[6px] bg-gray-50 h-[34px] dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 relative w-full cursor-default rounded-md border border-gray-300 py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm";
const classNameError = "pt-[6px] bg-gray-50 h-[34px] dark:bg-gray-700 dark:border-red-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 relative w-full cursor-default rounded-md border border-red-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 sm:text-sm";

export default function InputSelect(props) {
    const {label, required, id, options, value, onChange, error, relativeDropdown} = props;

    function getOptionById(idOption){
        if(options == null) return null;
        for(const option of options){
            if(option.id == idOption) return option
        }
        return null;
    }

    return (
        <>
            <Listbox value={value} onChange={(e) => {onChange({target: {id: id, value: e}})}}>
                {({ open }) => (
                <>
                {label == undefined ? <> </> : <label htmlFor={id} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{label + (required? " *" : "")}</label>}
                    <div className="relative mt-1 mb-[30px]">
                    <Listbox.Button className={error?.error ? classNameError : className}>
                        <span className="flex items-center">
                            <span className="block truncate">{getOptionById(value)?.key}</span>
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                            <HiOutlineChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </Listbox.Button>

                    <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className={(relativeDropdown ? "relative" : "absolute") + " dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"}>
                        {options?.map((option) => (
                            <Listbox.Option
                                key={option.id}
                                className={
                                    classNames(
                                        (option.id == value) ? 'text-blue-600 dark:text-blue-300 font-semibold' : 'text-gray-900 dark:text-white',
                                        'relative cursor-default select-none py-2 pl-3 pr-9'
                                    )
                                }
                                value={option.id}
                                disabled={option.disabled}
                            >
                            {({ selected, active }) => (
                                <div className="flex items-center">
                                    <span
                                    className={classNames(option.disabled ? 'text-gray-400 font-normal' : '', 'ml-3 block truncate')}
                                    >
                                    {option?.key}
                                    </span>
                                </div>
                            )}
                            </Listbox.Option>
                        ))}
                        </Listbox.Options>
                    </Transition>
                    </div>
                </>
                )}
            </Listbox>

            <p className="mt-[-30px] mb-[-20px] text-sm text-red-600 dark:text-red-500">{error?.message}</p>
        </>
    )
}