import { Tooltip} from "flowbite-react";
import {BsInfo} from "react-icons/bs";

export default function InputText(props) {
    const {label, value, error, onChange, id, required, type, className, placeholder, disabled, tooltip, displayErrorLabel} = props;
    const baseClassName = "mb-[30px] bg-gray-50 border text-sm focus:ring-blue-500 focus:border-blue-500 w-full py-1.5 pl-2.5 dark:bg-gray-700 rounded-md dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 "
    const normalClassName = baseClassName + "border-gray-300 " + (disabled?"text-gray-500":"text-gray-900") + " dark:border-gray-600 " + (disabled?"dark:text-slate-400":"dark:text-white");
    const errorClassName = baseClassName + "border-2 border-red-500 text-gray-900 dark:border-red-600 dark:text-white"

    function handleChange(e){
        let value = e.target.value;
        if(type == "number" && value == ""){
            value = null;

        }
        onChange({target: {id: id, value: value}});
    }

    return (   
        <div className={className}>
            {label != undefined ? <label className={"block mb-2 text-sm font-medium " + (disabled?"text-gray-500":"text-gray-900") + " " + (disabled?"dark:text-slate-400":"dark:text-gray-300")}>{label + (required ? " *" : "")}</label> : null}
            {tooltip?
                <div className="flex">
                    <input disabled={disabled} type={type == undefined ? "text" : type} value={value} id={id} onChange={handleChange} className={(error?.error? errorClassName : normalClassName)} placeholder={placeholder}/>
                    
                    <Tooltip
                        content={tooltip}
                        trigger="hover"
                        arrow={false}
                    >
                        <div className="text-white h-[30px] ml-[-35px] mt-[5px]">
                            <BsInfo style={{width: "100%", height: "100%"}}/>
                        </div>
                    </Tooltip>
                </div>
                :
                <input disabled={disabled} type={type == undefined ? "text" : type} value={value} id={id} onChange={handleChange} className={(error?.error? errorClassName : normalClassName)} placeholder={placeholder}/>
            }
            <div className="mt-[-30px] mb-[-20px]">
                {(displayErrorLabel != undefined && !displayErrorLabel) ? <></> : <p className="ml-1 text-sm text-red-600 dark:text-red-500">{error?.message}</p>}
            </div>
        </div>
    )
}