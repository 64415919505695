import {login as doLogin}  from "../api/auth";

export function login(data, errorCallback){
    doLogin(data).then((data) => {
        localStorage.setItem("user-site-qwpeoixycxc980", JSON.stringify(data.data));
        document.location.replace("/admin");
    }).catch((error) => {
        if(error.response.status == 401){
            errorCallback();
        } else {
            console.log(error);
        }
    })
}

export function logout(){    
    localStorage.removeItem("user-site-qwpeoixycxc980");
    document.location.replace("/login");
}

export function getAuthFromLocalStorage(){
    try { 
        return JSON.parse(localStorage.getItem("user-site-qwpeoixycxc980"));
    } catch(e) {
        return null
    }
}

export function auth(admin = false) {
    const auth = getAuthFromLocalStorage();
    if(auth != null && (!admin || auth.role == "admin" || auth.role == "superadmin")){
        return true;
    } else {
        return false;
    }
}

export function superadmin() {
    const auth = getAuthFromLocalStorage();
    if(auth != null && (auth.role == "superadmin")){
        return true;
    } else {
        return false;
    }
}