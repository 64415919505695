import { api } from ".";

export function getAllUsers(){
    return api.get('/users')
}

export function saveUser(id, data){
    if(id == 0){
        return api.post('/users', data)
    } else {
        return api.post('/users/' + id, data)
    }
}

export function getUser(id){
    return api.get("/users/" + id);
}

export function deleteUser(id) {    
    return api.delete("/users/" + id);
}

export function changePassword(id, password){
    return api.post('/users/changePassword/' + id, {password: password});
}

export function getCurrentUser(){
    return api.get('/users/current');
}