import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BsArrowRepeat } from "react-icons/bs"
import InputSelect from '../input/InputSelect'
import InputText from '../input/InputText'

export default function FormModal(props) {
  const UTC = 2;
  const open = props.open
  const setOpen = props.setOpen
  const saveEvent = props.onSave
  const deleteEvent = props.onDelete
  const currentUser = props.currentUser
  const today = new Date()
  today.setHours(today.getHours() + UTC);
  const defaultEndHour = new Date();
  defaultEndHour.setHours(defaultEndHour.getHours() + UTC + 2);

  const cancelButtonRef = useRef(null)

  const lstOptions = [
    {id: 0, key: "Réservation non-répétitif"},
    {id: 1, key: "Toutes les semaines"},
    {id: 2, key: "Tous les mois"}
  ]

  const defaultForm = {
    id: 0,
    title: "",
    dateStart: today.toISOString().slice(0, 10),
    dateOfDay: today.toISOString().slice(0, 10),
    timeStart: today.toISOString().slice(11, 16),
    timeEnd: defaultEndHour.toISOString().slice(11, 16),
    repeat: 0,
  }

  const errorFormDefault = {
    title: {},
    dateStart: {},
    timeStart: {},
    timeEnd: {}
  }

  const [formValues, setFormValues] = useState(defaultForm)
  const [errorForm, setErrorForm] = useState(errorFormDefault);
  const [title, setTitle] = useState("Réserver")

  useEffect(() => {
    if(props.editedEvent != null) {
      setFormValues({...props.editedEvent, dateStart: props.editedEvent.dateOfDay})
      setTitle("Réservation")
    } else {
      const startDefault = props.defaultStartDate != null ? props.defaultStartDate : defaultForm.dateStart
      const startTimeDefault = props.defaultStartTime != null ? props.defaultStartTime : defaultForm.timeStart
      const stopTimeDefault = (+startTimeDefault.slice(0, 2) + 2) + ":00"
      setFormValues({
        ...defaultForm,
        dateStart: startDefault,
        timeStart: startTimeDefault,
        timeEnd: stopTimeDefault
      })
      setTitle("Réserver")
    }
  }, [props.editedEvent, props.defaultStartDate]);

  function handleChange(e){    
    const {id, value} = e.target;
    setFormValues({
        ...formValues,
        [id]: value
    })
  }

  function checkForm(){
      let tempErrorForm = errorFormDefault;
      let error = false;
      const requiredFields =  ["title", "date", "timeStart", "timeEnd"];
      for(const requiredField of requiredFields){
          if(formValues[requiredField] == ""){
              tempErrorForm = {
                  ...tempErrorForm,
                  [requiredField] : {
                      error: true,
                      message: "Champ obligatoire"
                  }
              }
              error = true;
          }
      }
      setErrorForm(tempErrorForm);
      return !error;
  }

  function selfUser(){
    if(currentUser == null || formValues.user == null) return false
    if(currentUser.id == formValues.user.id) return true
    if(currentUser.role.key != "user") return true
  }


  function save() {
    if(checkForm()){
      saveEvent(formValues);
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-800 dark:bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 dark:bg-gray-900">
                <div className="mb-10">
                  <h2 className="text-base flex justify-between font-semibold leading-7 text-gray-900 dark:text-gray-100 mb-5">
                    {title} 
                    {
                      formValues.user == undefined ? <></> :
                      <span className={"px-3 py-1 rounded-lg text-gray-700 text-sm" + " bg-" + formValues.user.color + "-300"}>{formValues.user.userName}</span>
                      }
                  </h2>
                  <div className='mb-3'>
                    <InputText placeholder="Ajouter un titre" id="title" value={formValues.title} onChange={handleChange} error={errorForm.title} displayErrorLabel={false}/>
                  </div>

                  <div className='mb-3'>
                    <InputText value={formValues.dateStart} id="dateStart" type="date" onChange={handleChange} error={errorForm.dateStart} displayErrorLabel={false}/>
                  </div>

                  <div className="grid grid-cols-5 mb-3">
                    <div className='col-span-2'>
                      <InputText value={formValues.timeStart} id="timeStart" type="time" onChange={handleChange} error={errorForm.timeStart} displayErrorLabel={false}/>
                    </div>
                    <div>
                      <p className='text-center text-2xl dark:text-gray-100'>-</p>
                    </div>

                    <div className='col-span-2'>
                      <InputText value={formValues.timeEnd} id="timeEnd" type="time" onChange={handleChange} error={errorForm.timeEnd} displayErrorLabel={false}/>
                    </div>                                      
                  </div>
                  
                  <div className='mb-10'>
                      <InputSelect id="repeat" onChange={handleChange} icon={<BsArrowRepeat />} options={lstOptions} value={formValues.repeat} relativeDropdown={true} displayErrorLabel={false}/>
                  </div> 
                </div>

                <div className="mt-10 flex items-center justify-between">
                    <div>
                      {
                        (formValues.id == 0 || !selfUser()) ? <></> :
                        <button
                            className="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                            onClick={() => {deleteEvent(formValues)}}
                        >
                            Supprimer
                        </button>
                      }
                    </div>
                    <div className='flex gap-x-4'>
                      <button onClick={() => {setOpen(false)}} type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                          Annuler
                      </button>
                      {
                        (!selfUser() && formValues.id != 0) ? <></>:
                        <button
                        className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        onClick={save}
                        >
                          Enregistrer
                        </button>
                      }
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
