import { api } from ".";

export function getAllEvents(){
    return api.get('/events')
}

export function saveEvent(id, data){
    if(id == 0){
        return api.post('/events', data)
    } else {
        return api.post('/events/' + id, data)
    }
}

export function getEvent(id){
    return api.get("/events/" + id);
}

export function deleteEvent(id, data) {    
    return api.post("/events/del/" + id, data);
}