import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";
import { MONTH } from "../../utils/date";
import ConfirmDelete from "./ConfirmDelete";
import ConfirmEdit from "./ConfirmEdit";
import FormModal from "./FormModal";
import { MonthView } from "./MonthView";
import WeekView from "./WeekView";
import { FaPlus } from 'react-icons/fa';

export function Calendar(props) {
    const {events, onSave, onDelete, currentUser} = props

    const date = new Date();
    const today = {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()        
    }

    const [view, setView] = useState(localStorage.getItem("calendar-view")); //0-monthView, 1-weekView
    const [currentDate, setCurrentDate] = useState({...today})
    const [openForm, setOpenForm] = useState(false);
    const [editedEvent, setEditedEvent] = useState(null);
    const [defaultStartDate, setDefaultStartDate] = useState(null);
    const [defaultStartTime, setDefaultStartTime] = useState(null);
    const [openConfirmDeleteForm, setOpenConfirmDeleteForm] = useState(false);
    const [openConfirmEditForm, setOpenConfirmEditForm] = useState(false);
    const [confirmFormEvent, setConfirmFormEvent] = useState(null);


    /**
     * Permet de changer de jour
     * nbDay, le nombre de jour a se déplacer (négatif ou positif)
     */
    function navigueDay(nbDay){
      let newDayDate = new Date(currentDate.year, currentDate.month, currentDate.day);
      newDayDate.setDate(newDayDate.getDate() + nbDay);
      setCurrentDate({
        year: newDayDate.getFullYear(),
        month: newDayDate.getMonth(),
        day: newDayDate.getDate()
      })
    }

    /**
     * Permet de changer de mois
     * nbMonth, le nombre de mois a se déplacer (négatif ou positif)
     */
    function navigueMonth(nbMonth){
        //Permet de changer de mois
        let newMonth = currentDate.month + nbMonth; //set janvier = 0
        let newYear = currentDate.year;
        if(newMonth < 0 || newMonth > 11){
            //Doit changer d'année
            const nbYear = Math.floor(newMonth/12);
            newMonth = newMonth%12;
            newYear = currentDate.year + nbYear;
            if(newMonth < 0){
                newMonth = 12 + newMonth
            }
        }
        
        setCurrentDate({
          day: currentDate.day,
          month: newMonth,
          year: newYear
        });
    }

    function handleChangeView(view){
      setView(view);
      localStorage.setItem("calendar-view", view);
    }

    function handleCalendarToday(){
      setCurrentDate({...today});
    }

    function handleCalendarNext(){
        //Action calendrier suivant
        if(view == 0){
            navigueMonth(1)
        } else {
          navigueDay(7)
        }
    }

    function handleCalendarPrevious(){
        //Action calendrier precedant
        if(view == 0){
            navigueMonth(-1)
        } else {
          navigueDay(-7)
        }
    }

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    function editEvent(event, date){
      setEditedEvent({
        ...event,
        dateOfDay: date,
        dateOfDayUpdate: date
      });
      setOpenForm(true)
    }

    function newEvent(){
      setEditedEvent(null)
      setOpenForm(true)
    }

    function saveEvent(event){
      if(event.repeat != 0 && event.id != 0){
        setOpenForm(false);
        setConfirmFormEvent(event);
        setOpenConfirmEditForm(true);
      } else {
        onSave(event)
        setOpenForm(false);
      }
    }

    function confirmEdit(event, mode){
      onSave(event, mode)
      setOpenConfirmEditForm(false)
      setEditedEvent(null);
    }

    function deleteEvent(event){
      if(event.repeat != 0){
        setOpenForm(false) 
        setConfirmFormEvent(event);
        setOpenConfirmDeleteForm(true);
      } else {
        onDelete(event)
        setOpenForm(false)        
      }
      setEditedEvent(null);
    }

    function confirmDelete(event, mode){
      onDelete(event, mode)
      setOpenConfirmDeleteForm(false)
      setEditedEvent(null);
    }

    function addEvent(date, time=null){
      setEditedEvent(null)
      setDefaultStartDate(date)
      setDefaultStartTime(time)
      setOpenForm(true)
    }

    let initTouchX;

    function handleTouchStart(e){
      initTouchX = e.changedTouches[0].screenX;
    }

    function handleTouchMove(e){
    }

    function handleTouchEnd(e){
      const delta = initTouchX - e.changedTouches[0].screenX;
      const minDelta = 50;
      if(delta > minDelta || delta < -minDelta){
        if(delta > 0){
          handleCalendarNext();
        } else {
          handleCalendarPrevious();
        }
      }
    }

    return <div className="rounded-md lg:flex lg:h-full lg:flex-col">
        <header className="flex items-center justify-between px-6 py-4 lg:flex-none h-[65px]">
        <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
          <time dateTime="2022-01">{MONTH[currentDate.month]} {currentDate.year}</time>
        </h1>
        <div className="flex items-center">
          <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch dark:bg-gray-800 ">
            <button
              onClick={handleCalendarPrevious}
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50 dark:border-none dark:hover:bg-gray-700 dark:text-white"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              onClick={handleCalendarToday}
              type="button"
              className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block dark:hover:bg-gray-700 dark:text-white dark:border-none"
            >
              Aujourd'hui
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              onClick={handleCalendarNext}
              type="button"
              className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50 dark:border-none dark:hover:bg-gray-700 dark:text-white"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative" onChange={handleChangeView}>
              <Menu.Button
                type="button"
                className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:ring-gray-800"
              >
                {view == 0? "Mois": "Semaines"}
                <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400 dark:text-white" aria-hidden="true" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {handleChangeView(1)}}
                          className={classNames(
                            active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm pointer w-full text-left dark:text-white'
                          )}
                        >
                          Semaines
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {handleChangeView(0)}}
                          className={classNames(
                            active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm pointer w-full text-left dark:text-white'
                          )}
                        >
                          Mois
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              onClick={newEvent}
              type="button"
              className="ml-6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Réserver
            </button>
          </div>
          <Menu as="div" className="relative ml-6 md:hidden">
            <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500 dark:text-white">
              <span className="sr-only">Open menu</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:divide-gray-600">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={newEvent}
                        href="#"
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm pointer w-full text-left dark:text-white'
                        )}
                      >
                        Réserver
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={handleCalendarToday}
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm pointer w-full text-left dark:text-white'
                        )}
                      >
                        Aujourd'hui
                      </button>
                    )}
                  </Menu.Item>
                </div>
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {handleChangeView(1)}}
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm pointer w-full text-left dark:text-white'
                        )}
                      >
                        Semaines
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => {handleChangeView(0)}}
                        className={classNames(
                          active ? 'bg-gray-100 dark:bg-gray-700 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm pointer w-full text-left dark:text-white'
                        )}
                      >
                        Mois
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        </header>
        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onTouchMove={handleTouchMove}>
        {
          view == 0?
            <MonthView currentDate={currentDate} today={today} events={events} editEvent={editEvent} addEvent={addEvent}></MonthView>
          :
            <WeekView currentDate={currentDate} today={today} events={events}  editEvent={editEvent} addEvent={addEvent}></WeekView>
        }
          
        </div>
        <FormModal open={openForm} setOpen={setOpenForm} editedEvent={editedEvent} onSave={saveEvent} onDelete={deleteEvent} currentUser={currentUser} defaultStartDate={defaultStartDate} defaultStartTime={defaultStartTime}/>

        <ConfirmDelete open={openConfirmDeleteForm} setOpen={setOpenConfirmDeleteForm} event={confirmFormEvent} confirmDelete={confirmDelete} />

        <ConfirmEdit open={openConfirmEditForm} setOpen={setOpenConfirmEditForm} event={confirmFormEvent} confirmEdit={confirmEdit} />

        <div onClick={newEvent} className="h-[60px] w-[60px] bg-blue-500 dark:bg-blue-700 rounded-lg fixed lg:hidden bottom-4 right-4 flex">
          <FaPlus className="text-white h-[40px] w-[40px] m-auto"/>
        </div>

    </div>
}