import { useState, useEffect } from "react";
import { getAllRoles } from "../../../api/roles"
import Breadcrumb from "../../../component/Breadcrumb";
import { Table } from "../../../component/Table"

const struct = [
    {key: "key", text: "Role"},        
    {key: "description", text: "Description"}
]

export function Roles(){
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        getAllRoles().then((value) => {
            setRoles(value.data);
        });
    }, []);

    return (
        <div>
            <Breadcrumb className="pl-5" current="Roles"/>
            <div className="flex items-end justify-between px-1 sm:px-10 py-5">
                <h2 className="text-2xl sm:text-4xl w-full sm:w-auto text-center font-bold dark:text-white">Roles</h2> 
            </div>
            <div className="m-1 sm:m-10">
                <Table headers={struct} data={roles}/>
            </div>
        </div>        
    )
}