import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import InputRadio from '../input/InputRadio';

export default function ConfirmEdit(props) {

  const {open, setOpen, event, confirmEdit} = props;

  const options = [
    {id: 1, key: "Modifier uniquemet cet évènement."},
    {id: 2, key : "Modifier cet évènement et tous les suivants."},
    {id: 0, key: "Modifier tous les évènement."},
  ]

  const [deleteType, setDeleteType] = useState(1);

  function handleChange(e){    
    const {value} = e.target;
    setDeleteType(value);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-800 dark:bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 dark:bg-gray-900">                
                <h2 className="text-base flex justify-between font-semibold leading-7 text-gray-900 dark:text-gray-100 mb-5">
                  Comment modifier cet évènement?
                </h2>
                <div className="mb-10">
                  <InputRadio options={options} onChange={handleChange} value={deleteType}/>
                </div>

                <div className="mt-10 flex items-center justify-between">
                    <div>
                      <button onClick={() => {setOpen(false)}} type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                          Annuler
                      </button>
                    </div>
                    <div className='flex gap-x-4'>
                      <button
                          className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          onClick={() => {confirmEdit(event, deleteType)}}
                      >
                          Modifier
                      </button>
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
