import { useState } from 'react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function InpuTCombo(props) {
    const {label, onChange, value, id, required, options} = props;
  const [query, setQuery] = useState('')

  const filteredoptions =
    query === ''
      ? options
      : options.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase())
        })

    function valueByName(name){
        for(const option of options){
            if(option.name == name) return option
        }
        return '';
    }

    function handleChange(e){
        onChange({target: {value: e.name, id: id}});
    }

  return (
    <>
      <Combobox as="div" value={valueByName(value)} onChange={handleChange}>
        <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">{label + (required ? " *" : "")}</Combobox.Label>
        <div className="relative mt-1">
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 text-sm py-1.5 pl-3 pr-10 text-gray-900 dark:text-white shadow-sm sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(option) => option?.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>

          {filteredoptions.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-100 dark:bg-gray-700 py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredoptions.map((option) => (
                <Combobox.Option
                  key={option.id}
                  value={option}  
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                      active ? 'bg-blue-600 text-white' : 'text-gray-900 dark:text-white'
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{option.name}</span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            active ? 'text-white' : 'text-blue-600 dark:text-white'
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  )
}