import { faAddressBook } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MusicalNoteIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { getContact } from "../api/contacts";
import { getAllMembers } from "../api/members";
import { getIdByInstrument } from "../utils/enum";
import { IoIosSchool } from "react-icons/io";

const EMA = () => {
    const [contact, setContact] = useState({})
    const [members, setMembers] = useState([])

    function loadMembers() {
        getAllMembers().then((data) => {
            const tempMembers = []
            for(const member of data.data){
                if(member.school != null){
                    tempMembers.push({...member, ...member.school});
                }            
            }

            //Sort  musicians
            tempMembers.sort((a, b) => getIdByInstrument(a.instrument) - getIdByInstrument(b.instrument));
            setMembers(tempMembers);
        })

    }

    function loadContact() {
        getContact(1).then((data) => {
            setContact(data.data);
        })
    }

    useEffect (() => {
        loadContact();
        loadMembers();
    }, []) 
    return <div className="max-w-[1000px] m-auto p-5 text-justify">        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Ecole de musique</h1>
            <small className="font-semibold text-gray-500 md:text-2xl text-lg md:ml-10 md:text-4xl">de l'Avenir de Saxon</small>
        </div>

        <div>
            <p className="mt-10 mb-10 text-base font-normal tracking-tight text-gray-900">
                L'Avenir de saxon investit dans le futur en soutenant la formation de jeunes musiciens. Nous encourageons les jeunes à suivre leur formation au conservatoire. Grâce à leur bonne formation, ils pourront rapidement rejoindre les rangs de la fanfare.
            </p>
            <p>
                Si vous êtes intéressé, n'hésitez pas à contacter notre responsable de l'école de musique.
            </p>
        </div>
        <div className="m-auto mt-10 grid grid-cols-1 gap-4 mb-10 max-w-[1000px]">
            <div href="#" className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow md:flex-row">
                <img className="md:h-[250px] h-[200px] mb-3 mr-3 rounded-lg sm:mb-0" src={contact.responsableEMAPhoto ? contact.responsableEMAPhoto : "/img/pp/anonym.jpg"} alt=""/>
                <div className="p-4 pt-0 h-full">
                    <h5 className="my-2 md:text-xl text-base font-bold tracking-tight text-gray-900 text-center">Responsable de l'école de musique</h5>
                    <div>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faAddressBook} /></span>{contact.responsableEMAName}</p>
                        <p className="mb-0 md:text-m text-sm font-normal text-gray-700 "><span className="mr-5"><FontAwesomeIcon icon={faPhone} /></span>{contact.responsableEMAPhone}</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="p-5 mb-4 border border-gray-100 rounded-lg bg-gray-50">
            <time className= "md:text-lg text-sm font-semibold text-gray-900">Membres de l'école de musique</time>
            <div className="mt-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    {members.map((member) => {
                        return (     
                            <div key={member.name} className="items-center block p-3 flex m-1 rounded-lg bg-white">
                                <img className="h-16 mb-3 mr-3 rounded-lg sm:mb-0" src={member.photo ? member.photo : "/img/pp/anonym.jpg"} alt=""/>
                                <div className="text-gray-600">
                                    <div className="font-medium text-gray-900 md:text-normal text-sm">{member.name}</div>                            
                                    <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                        <MusicalNoteIcon className="h-4 w-6 text-gray-500" />
                                        {member.instrument}
                                    </span>    <br />                 
                                    <span className="inline-flex items-center text-xs font-normal text-gray-500">
                                        <IoIosSchool className="h-4 w-6 text-gray-500" />
                                        {member.professor}
                                    </span> 
                                </div>
                            </div>
                        ) 
                    })}
            </div>
        </div>
    </div>
};
  
export default EMA;