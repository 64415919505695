import { api } from ".";

export function saveContact(id, data){
    if(id == 0){
        return api.post('/contact', data)
    } else {
        return api.post('/contact/' + id, data)
    }
}

export function getContact(id){
    return api.get("/contact/" + id);
}