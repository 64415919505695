import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllDates } from "../api/agenda";

const Calendar = () => {
    const navigate = useNavigate();

    const [dates, setDates] = useState([]);

    function loadDates() {
        getAllDates().then((data) => {
            setDates(data.data);
        })
    }

    useEffect (() => {
        loadDates();
    }, [])

    function navigateTo(href) {
        window.scrollTo(0, 0);
        navigate("/" + href);
    }
    
    return <div className="max-w-[800px] m-auto p-5">        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Agenda</h1>
            <small className="font-semibold text-gray-500 text-lg md:ml-10 md:text-4xl">Saison 2023-2024</small>
        </div>
        <div>
            <ol className="mt-10 border-l border-gray-200 z-10">
                {
                    dates.map((event) => {
                        return (
                            <li key={event.date} className="ml-6">            
                                <span className="ml-[-36px] flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -left-3 ring-8 ring-white">
                                    <svg aria-hidden="true" className="w-3 h-3 text-blue-800" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clipRule="evenodd"></path></svg>
                                </span>
                                <h3 className="flex items-center mb-0 md:mb-1 md:text-lg text-sm font-semibold text-gray-900 mt-[-26px] ml-2">{event.title} <span className="bg-blue-100 text-blue-800 md:text-sm text-xs font-medium mr-2 px-1.5 py-0.1 rounded ml-2 hidden md:block">{event.date}</span></h3>
                                <span className="bg-blue-100 text-blue-800 md:text-sm text-xs font-medium mr-2 px-1.5 py-0.1 rounded block md:hidden w-fit ml-2">{event.date}</span>
                                
                                <p className="md:text-base text-sm font-normal text-gray-500 ml-5">{event.description}</p>
                                <button onClick={() => {navigateTo("events/" + event.id)}} className='underline text-gray-800 md:text-base text-sm mb-10 ml-5'>En savoir plus</button>
                            </li>
                        )
                    })
                }          
                
            </ol>
        </div>
    </div>
};
  
export default Calendar;