import { useState } from "react";
import GalleryModal from "../component/modal/GalleryModal";

const History = () => {
    const [open, setOpen] = useState(false);
    const [src, setSrc] = useState("");
    function zoom(e, url=""){
        if(url == ""){
            url = e.target.src;
        }
        setSrc(url);
        setOpen(true);
    }

    return <div className="max-w-[1400px] m-auto p-5 text-justify">        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Notre Histoire</h1>
            <small className="font-semibold text-gray-500 md:text-2xl text-lg md:ml-10 md:text-4xl">1905 - 2014</small>
        </div>
        <div className="md:grid md:grid-cols-3 md:gap-10 mt-10 md:mt-[65px]">

            <div className="flex flex-col justify-between">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Fondation de l'Avenir</p>
                    <p className="text-gray-600 font-medium md:text-base text-sm mb-2">En <span className="md:text-lg text-sm font-bold">1905</span>, au pied de la Pierre-à-Voir, par des temps difficiles, des hommes proches d'un même idéal politique et passionnés de musique fondent une fanfare.</p>
                    <p className="text-gray-600 font-medium md:text-base text-sm mb-2">Elle s'appellera l'Avenir, car ses fondateurs la croient capable de vivre éternellement.</p>
                    <p className="text-gray-600 font-medium md:text-base text-sm">C'est l'enthousiasme.</p>
                </div>
                
                <img onClick={zoom} alt="" className="rounded-lg mt-5 cursor-pointer" src="./img/history/listemembre1906.png" />
            </div>
            <div className="col-span-2 mt-5 md:mt-0">
                <img alt="" className="rounded-lg cursor-pointer" onClick={(e) => {zoom(e, "./img/history/photo_legende/1908.png", "w-[1000px]")}} src="./img/history/photo1906.png" />
            </div>

            
                
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="flex flex-col justify-between col-span-2 mt-5 md:mt-0">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Premier drapeau</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1906</span>, elle inaugure son premier drapeau. Signe distinctif des musiciens :</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">chapeau de feutre vert tendre remplacé en <span className="md:text-lg text-sm font-bold">1915</span> par un canotier blanc entouré d'un ruban aux couleurs du blason communal.</p>
                </div>
            </div>
            <div className="md:flex md:flex-row-reverse">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg max-h-[300px] m-auto" src="./img/history/drapeau1906.png" />
            </div>   
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="col-span-3 m-auto mt-5 md:mt-0">
                <p className="text-gray-600 font-medium mb-2 text-center max-w-[1000px] md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1919</span>, la Fanfare adhère à la Fédération des Fanfares et Chorales du Centre. Au sein de cette association, elle participe à son premier festival à Fully. Auparavent, elle faisait partie de la fédération du Bas-Valais apolitique où elle participa à plusieurs festivals.</p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="hidden md:block">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg max-h-[300px]" src="./img/history/cercle.png" />
            </div>
            <div className="col-span-2 mt-5 md:mt-0">
                <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Le Cercle de l'Avenir</p>
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1921</span>, elle construit le Cercle de l'Avenir servant comme local de répétitions et salle de spectacles.</p>
            </div> 
            <div className="md:hidden block">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg max-h-[300px]" src="./img/history/cercle.png" />
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>

            
            <div className="col-span-3 m-auto mt-5 md:mt-0">
                <p className="text-gray-600 font-medium mb-2 text-center max-w-[1000px] md:text-base text-sm">Elle organise pour la 1ère fois en <span className="md:text-lg text-sm font-bold">1922</span> le festival des fanfares et chorales du Centre.</p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="flex flex-col justify-between col-span-3 mt-5 md:mt-0">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Concours international</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1927</span>, elle participe au concours international de Reims où elle obtient 2 couronnes de laurier en première division.</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">5 ans plus tard, elle se produit à la foire de Milan ainsi qu'à Turin, Gènes et Nice.</p>
                </div>
            </div>        
            <div>
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg md:h-[250px] mb-5 md:mb-10" src="./img/history/concours-musique.png" />
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg md:h-[250px]" src="./img/history/diplome.png" />
            </div>
            <div className="col-span-2 mt-5 md:mt-0">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/concours.png" />
                <p className="mb-3 text-sm font-medium italic text-center text-gray-600 md:text-base text-sm">L'Avenir de Saxon au concours international de musique à Reins - juin 1927</p>
            </div>    
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>

            
            <div className="hidden md:block">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg max-h-[300px]" src="./img/history/deuxiemedrapeau.png" />
            </div>   
            <div className="flex flex-col justify-between col-span-2 mt-5 md:mt-0">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Deuxième drapeau</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1935</span>, elle inaugure son deuxième drapeau.</p>
                </div>
            </div>
            <div className="block md:hidden">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg md:max-h-[300px]" src="./img/history/deuxiemedrapeau.png" />
            </div>   
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>

            
            <div className="flex flex-col justify-between col-span-2 mt-5 md:mt-0">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Café Bar du Cercle de l'Avenir</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1936</span>, elle obtient une concession pour ouvrir le Café Bar du Cercle de l'Avenir.</p>
                </div>
            </div>
            <div className="flex flex-row-reverse">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/cafebar.png" />
            </div>   
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="flex-row-reverse hidden md:flex">
                <img onClick={(e) => {zoom(e, "./img/history/photo_legende/1948.png")}} alt="" className="cursor-pointer rounded-lg" src="./img/history/photo1945.png" />
            </div>   
            <div className="flex flex-col justify-between col-span-2 mt-5 md:mt-0">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">30ème festival</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="text-lg font-bold">1945</span>, elle organise le 30ème festival de la Fédération. A cette occasion, elle étrene la fameuse casquette blanche.</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">On agrandit l'année suivante la salle de spectacle du Cercle pour abriter le cinéma Rex.</p>
                </div>
            </div>
            <div className="flex flex-row-reverse block md:hidden">
                <img onClick={(e) => {zoom(e, "./img/history/photo_legende/1945.png")}} alt="" className="cursor-pointer rounded-lg" src="./img/history/photo1945.png" />
            </div>  
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="col-span-3 m-auto mt-5 md:mt-0">
                <p className="text-gray-600 font-medium mb-2 text-center md:text-base text-sm">Avec la société de musique la Concordia, elle a la responsabilité de la fête cantonale des musiques en <span className="md:text-lg text-sm font-bold">1952</span>.</p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>

            
            <div className="col-span-3 m-auto mt-5 md:mt-0">
                <p className="text-gray-600 font-medium mb-2 text-center max-w-[1000px] md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1954</span>, un certain nombre de musiciens quittent la société et fondent le Corps de Musique.</p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="flex flex-row-reverse hidden md:flex">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/costumenoir.png" />
            </div>   
            <div className="flex flex-col justify-between col-span-2 mt-5 md:mt-0">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Premiers costumes</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1959</span>, elle inaugure ses premiers cosutmes (noirs avec passements dorés).</p>
                </div>
            </div>
            <div className="flex flex-row-reverse flex md:hidden">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/costumenoir.png" />
            </div>   
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="col-span-3 m-auto mt-5 md:mt-0">                
                <p className="text-gray-600 font-medium mb-2 text-center md:text-base text-sm">Dès <span className="md:text-lg text-sm font-bold">1962</span>, la Fédération s'appelle Fédération des Fanfares Démocrates Chrétiennes du Centre.</p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="hidden md:block">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/jesaispasquicest.png" />
            </div>  
            <div className="mt-5 md:mt-0 text-justify">                
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1965</span>, l'Avenir organise le 50ème festival de la Fédération et fête en même temps son 60ème anniversaire.</p>
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">Cette même année, à Saillon, 7 fanfares démocrates chrétiennes fondent l'Amicale de fanfares démocrates chrétiennes du district de Martigny.</p>
            </div>
            <div className="block md:hidden mt-5">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/jesaispasquicest.png" />
            </div> 
            <div>
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg mt-5 md:mt-0" src="./img/history/50emefestival.png" />
            </div>  
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="col-span-3 m-auto mt-5 md:mt-0">                
                <p className="text-gray-600 font-medium mb-2 text-center md:text-base text-sm">Le cinéma Rex de Saxon ferme ses portes le 31 décembre <span className="md:text-lg text-sm font-bold">1968</span></p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="col-span-3 m-auto mt-5 md:mt-0">                
                <p className="text-gray-600 font-medium mb-2 text-center md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1970</span>, elle organise la 6ème Amicale du district.</p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>
            
            
             
            <div className="flex flex-col justify-between col-span-2 mt-5 md:mt-0">  
                <div>
                    <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">Troisième drapeau</p>
                    <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1971</span>, elle inaugure son Troisième drapeau.</p>
                </div>
            </div>
            <div>
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg max-h-[300px] mt-5 md:mt-0" src="./img/history/troisiemedrapeau.png" />
            </div>  
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="col-span-3 m-auto mt-5 md:mt-0">                
                <p className="text-gray-600 font-medium mb-2 text-center md:text-base text-sm">Elle organise en <span className="md:text-lg text-sm font-bold">1977</span>, la 11ème Amicale.</p>
            </div>
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>
        </div>
    
        <div className="md:grid md:grid-cols-2 gap-10 mt-5 md:mt-10">
            <div className="">                
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1981</span>, elle fête son 75ème anniversaire en présentant une 2ème couvée de costumes (noirs avec passements blancs).</p>
            </div>
            <div className="col-span-2 flex flex-row-reverse mt-5 md:mt-0">
                <img onClick={(e) => {zoom(e, "./img/history/photo_legende/1981.png")}} alt="" className="cursor-pointer rounded-lg" src="./img/history/75eme.png" />
            </div> 
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>

            
            <div className="hidden md:block">
                <img onClick={(e) => {zoom(e, "./img/history/photo_legende/1986.png")}} alt="" className="cursor-pointer rounded-lg" src="./img/history/1984.png" />
            </div> 
            <div className="mt-5 md:mt-0">                
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">Elle met sur pied en <span className="md:text-lg text-sm font-bold">1984</span>, le 69ème festival de la Fédération.</p>
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">1988</span>, son 4ème drapeau est inauguré lors de la 18ème Amicale.</p>
            </div>
            <div className="block md:hidden">
                <img onClick={(e) => {zoom(e, "./img/history/photo_legende/1986.png")}} alt="" className="cursor-pointer rounded-lg" src="./img/history/1984.png" />
            </div> 
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>

            
            
            <div className="mt-5 md:mt-0">                
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">Lors de la 24ème Amicale, en <span className="md:text-lg text-sm font-bold">1996</span>, elle inaugure ses actuels costumes et fête son 90ème anniversaire.</p>
            </div>
            <div className="col-span-2 flex flex-row-reverse mt-5 md:mt-0">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/1996.png" />
            </div> 
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>
        </div>

        <div className="md:grid md:grid-cols-3 md:gap-10 mt-5 md:mt-10">
            <div>
                <p className="mb-3 md:text-2xl text-lg font-bold text-gray-700">90ème festival</p>
                <p className="text-gray-600 font-medium mb-2 md:text-base text-sm">En <span className="md:text-lg text-sm font-bold">2005</span>, les 13-14-15 mai, la Fanfare l'Avenir a eu le plaisir d'organiser le 90ème festival de la Fédération qui coïncidait avec le 100ème anniversaire de la société.</p>
            </div>
            <div className="mt-5 md:mt-10">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg" src="./img/history/char.png" />
            </div> 
            <div className="md:flex md:flex-row-reverse mt-5 md:mt-10">
                <img onClick={zoom} alt="" className="cursor-pointer rounded-lg m-auto md:m-0" src="./img/history/affiche.png" />
            </div> 
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>


            <div className="col-span-3 m-auto mt-5 md:mt-10">
                <img onClick={(e) => {zoom(e, "./img/history/photo_legende/2005.png")}} alt="" className="cursor-pointer rounded-lg" src="./img/history/2005.png" />
                <p className="mb-3 text-sm font-medium italic text-center text-gray-600 md:text-base text-sm">Photo de l'Avenir lors du 90ème festival</p>
            </div> 
            <div className="col-span-3 bg-black h-[3px] rounded-full w-full m-auto mt-5 md:mt-0"></div>
        </div>
        <GalleryModal open={open} setOpen={setOpen} src={src}/>
    </div>
};
  
export default History;