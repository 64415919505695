import { Navbar, Sidebar } from "flowbite-react"
import { useState } from "react";
import { HiCalendar, HiMenuAlt2, HiMoon, HiSun, HiUser, HiKey, HiHome, HiLogout, HiUsers } from "react-icons/hi"
import { BiSolidContact } from "react-icons/bi"
import { FaCrown } from "react-icons/fa6";
import { Outlet, useNavigate } from "react-router-dom"
import { auth, logout, superadmin } from "../../utils/auth";
import Error from "../../component/alert/Error"
import { setErrorCallback, setServerMissingCallback } from "../../api";
import "./index.css"

function AdminLayout() {
    document.title = "Avenir de Saxon - Admin"

    document.body.classList.add("admin")

    const [dark, setDark] = useState(false);
    const [error, setError] = useState(false);
    const [serverMissing, setServerMissing] = useState(false);
    const [sidebar, setSidebar] = useState(false);

    if(JSON.parse(localStorage.getItem("darktheme-site-qwpeoixycxc980"))){
      if(!dark){
        setDark(true);
        document.documentElement.classList.add('dark');
      }
    }

    function showError(){
      setError(true);
    }

    function hideError(){
      setError(false)
    }

    function showServerMissing(){
      setServerMissing(true);
    }

    function hideServerMissing(){
      setServerMissing(false)
    }

    setErrorCallback(showError);
    setServerMissingCallback(showServerMissing)

    if(dark){
      document.documentElement.classList.add('dark');
    }

    function toogleSideBar(){
      setSidebar(!sidebar);
    }

    function hideSideBar(){
      setSidebar(false);
    }

    function toogleDark(){
      const tempDark = dark;
      setDark(!tempDark);
      
      localStorage.setItem("darktheme-site-qwpeoixycxc980", JSON.stringify(!tempDark));
      if(!tempDark) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }

    const navigate = useNavigate();
    return (
      <div className="z-[1000]">
        <div className="fixed w-screen inset-0 shadow-lg h-[4.25rem] z-[1000]">
            <Navbar
                fluid={true}
                rounded={true}
                className="z-1000"
            >
            <Navbar.Brand>
                <span onClick={toogleSideBar} className="self-center whitespace-nowrap text-xl font-semibold dark:text-white lg:hidden cursor-pointer">
                    <HiMenuAlt2 className="mr-5"/>
                </span>
                <span onClick={hideSideBar} className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
                    Avenir de Saxon
                </span>
            </Navbar.Brand>
            <div onClick={hideSideBar} className="flex md:order-2 pb-[4px]">
                <button onClick={toogleDark} type="button" className="text-gray-900 mr-4 bg-white focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2.5 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    {
                      dark?
                      <HiSun style={{width: "25px", height: "25px", color: "gray"}}/>
                      :
                      <HiMoon style={{width: "25px", height: "25px", color: "gray"}}/>
                    }
                </button>
                <button onClick={logout} type="button" className="text-gray-900 mr-6 bg-white focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-2.5 py-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                    {
                      <HiLogout style={{width: "25px", height: "25px", color: "gray"}}/>
                    }
                </button>
            </div>
            </Navbar>
        </div>
        <div onClick={hideSideBar} style={{height: "calc(100vh - 4.5rem)", zIndex: 30}} className={"w-full fixed top-[4.3rem] backdrop-blur-[2px]" + (sidebar?"":" hidden")}>

        </div>
        <div style={{height: "calc(100vh - 4.5rem)"}} className={"z-[100] lg:block w-70 bg-gray-50 fixed top-[4.3rem] left-0 border-r-2 border-zinc-200 dark:border-gray-900 dark:bg-gray-900 sm:z-[10]" + (sidebar?"":" hidden") }>
          <Sidebar>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <Sidebar.Item
                  href="#"
                  icon={HiHome}
                  onClick={() => {navigate(""); hideSideBar()}}
                >
                  Home
                </Sidebar.Item>
              </Sidebar.ItemGroup>

              {
                auth(true)?<>
                <Sidebar.ItemGroup>
                  <Sidebar.Item
                    href="#"
                    icon={HiCalendar}
                    onClick={() => {navigate("agenda"); hideSideBar()}}
                  >
                    Agenda
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="#"
                    icon={BiSolidContact}
                    onClick={() => {navigate("contact"); hideSideBar()}}
                  >
                    Contact
                  </Sidebar.Item>
                </Sidebar.ItemGroup>
                <Sidebar.ItemGroup>
                  <Sidebar.Item
                    href="#"
                    icon={HiUsers}
                    onClick={() => {navigate("members"); hideSideBar()}}
                  >
                    Membres
                  </Sidebar.Item>
                  <Sidebar.Item
                    href="#"
                    icon={FaCrown}
                    onClick={() => {navigate("membreHonneurs"); hideSideBar()}}
                  >
                    Membres d'honneur
                  </Sidebar.Item>
                </Sidebar.ItemGroup>
                {
                  superadmin()?<>
                    <Sidebar.ItemGroup>
                      <Sidebar.Item
                        href="#"
                        icon={HiUser}
                        onClick={() => {navigate("users"); hideSideBar()}}
                      >
                        Utilisateurs
                      </Sidebar.Item>
                      <Sidebar.Item
                        href="#"
                        icon={HiKey}
                        onClick={() => {navigate("roles"); hideSideBar()}}
                      >
                        Roles
                      </Sidebar.Item>
                    </Sidebar.ItemGroup>
                  </>:
                  <></>
                }
                </>:null
              }
            </Sidebar.Items>
          </Sidebar>
        </div>
        <Error show={error} onClose={hideError} message={"Impossible d'accéder à la ressource (non authorisé)!"}/>
        <Error show={serverMissing} onClose={hideServerMissing} message={"Le serveur est inaccessible!"}/>
        <Outlet/>
      </div>
    )
}

export default AdminLayout;