import { useState } from "react";
import GalleryModal from "../component/modal/GalleryModal";
import album from "../helpers/album";

function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex != 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

const photos = [];

for(const category of album){
    for(const photo of category.photos){
        photos.push({url: category.location+"/"+photo, category: category.category});
    }
}

shuffle(photos)

const Album = () => {
    const [open, setOpen] = useState(false);
    const [src, setSrc] = useState("");

    function filter(e, category = null){
        const imgs = document.getElementsByTagName("img");
        if(category == null){
            for(const img of imgs){
                img.style.display = "block";
            }
        } else {            
            for(const img of imgs){
                if(img.getAttribute("category") == category){
                    img.style.display = "block";
                } else {
                    img.style.display = "none";
                }
            }
        }
        //Reset all btn className        
        const btns = document.getElementsByTagName("button");
        for(const btn of btns){
            btn.classList.remove("bg-blue-700");
            btn.classList.remove("text-white");
            btn.classList.add("bg-white");
        }

        e.target.classList.add("bg-blue-700");
        e.target.classList.add("text-white");
        e.target.classList.remove("bg-white");
    }

    function zoom(url){
        setSrc(url);
        setOpen(true);
    }

    return <div className="max-w-[1400px] m-auto p-2">   
        
        <div className="md:text-5xl text-2xl font-bold text-center md:flex md:w-fit md:m-auto md:items-end">
            <h1>Albums</h1>
            <small className="font-semibold text-gray-500 text-lg md:ml-10 md:text-4xl">Depuis 2015</small>
        </div>
        <div className="flex py-4 md:py-8 flex-wrap">
            <button onClick={(e) => {filter(e)}} type="button" className="text-white text-blue-700 bg-blue-700 hover:text-white border border-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full md:text-base text-sm font-medium px-5 py-2.5 text-center mr-3 mb-3">Tous les albums</button>
            {
                album.map((category) => {
                    return (
                        <button onClick={(e) => {filter(e, category.category)}} key={category.category} type="button" className="text-blue-700 hover:text-white border border-blue-600 bg-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3  ">{category.category}</button>
                    )
                })
            } 
        </div>    
        <div className="columns-2 md:columns-4 gap-3 mb-10">
            {                   
                photos.map((photo) => {
                    return (
                        <img key={photo.url} onClick={() => {zoom(photo.url)}} category={photo.category} className="w-full rounded-lg mb-3 cursor-pointer" src={photo.url} alt="" title={photo.category}/>
                    )
                }) 
            }
        </div>
        <GalleryModal open={open} setOpen={setOpen} src={src}/>
    </div>
};
  
export default Album;