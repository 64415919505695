import { api } from ".";

export function getAllMembers(){
    return api.get('/members')
}

export function saveMember(id, data){
    if(id == 0){
        return api.post('/members', data)
    } else {
        return api.post('/members/' + id, data)
    }
}

export function getMember(id){
    return api.get("/members/" + id);
}

export function deleteMember(id) {    
    return api.delete("/members/" + id);
}