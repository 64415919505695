import { api } from ".";

export function getAllDates(){
    return api.get('/dates')
}

export function saveDate(id, data){
    if(id == 0){
        return api.post('/dates', data)
    } else {
        return api.post('/dates/' + id, data)
    }
}

export function getDate(id){
    return api.get("/dates/" + id);
}

export function deleteDate(id) {    
    return api.delete("/dates/" + id);
}