import { FaCalendar, FaLocationDot } from "react-icons/fa6"
import React from "react"



export default class Spectacle {

    static forms(){
        return [
        ]        
    }
    
    static render(forms){
        
        return <div className="max-w-[1400px] m-auto p-5">
            <div className="xl:flex">
                <img className="m-auto xl:max-w-[600px]" src="/img/spectacle-1.jpg"/>
                <br />
                <img className="m-auto xl:max-w-[600px]" src="/img/spectacle-2.jpg"/>
            </div>        
        </div>
    }
}